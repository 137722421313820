import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "lookup";

const getBySection = async (section) => {
    try {
        const res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/${section}`,
            method: methods.GET
        });
        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        return null;
    }
}

// const deletePersonnel = async (id) => {
//     await axios.request({
//         url: `${baseURL}${rootRequestMapping}/delete/${id}`,
//         method: methods.GET
//     });
// }

// const checkCode = async (id, code, entity) => {
//     try {
//         let res = await axios.post(`${baseURL}uniquecheck/check`, {
//             "id": id,
//             "code": code,
//             "entity": entity
//         })
//         console.log(res)
//         return res.data;
//     }
//     catch (error) {
//         console.error(error)
//     }
// }


// const addPersonnel = async (firstName, lastName, email, department, role, code, id) => {
//     console.log("here");

//     let body = {

//         "name": firstName + " " + lastName,
//         "email": email,
//         "department": department,
//         "departmentName": department.name,
//         "role": role,
//         "userCode": code
//     };

//     if (id) body["id"] = id;


//     //let user = {
//     //    keycloak_id: "1591d100-7957-4cce-b7ce-f6ffa6f4ce7d",
//     //    username: "dylan2",
//     //    firstName: "Dylan",
//     //    lastName: "Olney",
//     //    email: "dylan.olney@mycit.ie",
//     //    roles:[]
//     //}

//     try {
//         let res = await axios.request({
//             url: `${baseURL}${rootRequestMapping}/save`,
//             method: methods.POST,
//             data: body
//         })
//         return res;
//     }
//     catch (error) {
//         return error;
//     }
// }

export { getBySection }