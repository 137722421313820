import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'testing'




export const updateResult = async(data) => {
    try {
        
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/report/updateresult`,
            method: methods.POST,
            data: data
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

export const insertResult = async (data) => {
    try {

        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/report/insertresult`,
            method: methods.POST,
            data: data
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}


export const jobSignOff = async (jobid) => {
    try {

        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/signoff/${jobid}`,
            method: methods.GET,
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

export const jobReopen = async (jobid) => {
    try {

        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/reopen/${jobid}`,
            method: methods.GET,
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}



export const retest = async (data) => {
    try {

        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/retestdetail`,
            method: methods.POST,
            data: data
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}


export const uploadDiagram = async(data, iDocId) => {
    try {
        let res = await axios.request({
            method: methods.POST,
            url: `${baseURL}${rootRequestMapping}/report/diagram/${iDocId}`,
            data: data,
            headers: {
                'Content-type': 'multipart/form-data',
                "Connection": "keep-alive",
            }

        });
        return (res.status === 200) ? res.data : false;
    }
    catch (err) {
        console.error(err)
        return false;
    }
}