import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { addTestMethod, checkCode } from '../../services/TestMethodsService';
//import SampleTypes from '../SampleTypes';
import { Alert, AlertTitle } from '@material-ui/lab';
import Switch from '@material-ui/core/Switch';
import { getDepartments } from '../../services/DepartmentsService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { getCategories } from '../../services/CategoryService';
import { MultiSelector, SingleSelector } from "../SelectComponents"
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { addTest, getUnits, getTestByTestMethodId } from "../../services/TestsService";
import { getEquipment } from '../../services/EquipmentService';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { testDataTypes } from './TestDataTypes';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function TestMethodDialog(props) {
    const { openDialog, openEdit, selectedItems, text, updateTable, onDialogClose } = props;
    const [formTestMethodId, setFormTestMethodId] = useState("");
    const [testMethodName, setTestMethodName] = useState("");
    const [internalSOPName, setInternalSOPName] = useState("");
    const [description, setDescription] = useState("");
	const [sequence, setSequence] = useState("");
    const [department, setDepartment] = useState({});
    const [defaultSamples, setDefaultSamples] = useState(6);
    const [departmentDetails, setDepartmentDetails] = useState([]);
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [categories, setCategories] = useState([]);
    const [alertBox, setAlertBox] = useState("");
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [isAlsoTest, setIsAlsoTest] = useState(false);
    const [testFieldsVisible, setTestFieldsVisible] = useState(false);

    const [testDataType, setTestDataType] = useState(null);
    const [unit, setUnit] = useState({});
    const [units, setUnits] = useState([]);
    const [equipments, setEquipment] = useState([]);
    const [equipmentDetails, setEquipmentDetails] = useState([]);
    const [isPass, setIsPass] = useState(false);
    const [testId, setTestId] = useState([]);
    const [testDBId, setTestDBId] = useState();
    const [fileHeader, setFileHeader] = useState();

    const [tabValue, setTabValue] = useState(0);

    const [calculationKey, setCalculationKey] = useState();
    const [calculationSeq, setCalculationSeq] = useState(-1);
    const sequences = [1, 2, 3, 4, 5]

    const [testMethodNameEnglish, setTestMethodNameEnglish] = useState("");
    const [testMethodNameFrench, setTestMethodNameFrench] = useState("");
    const [testMethodNameSpanish, setTestMethodNameSpanish] = useState("");
    const [testMethodNameItalian, setTestMethodNameItalian] = useState("");
    const [testMethodNameArabic, setTestMethodNameArabic] = useState("");
    const [testMethodNameRussian, setTestMethodNameRussian] = useState("");


    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    const handleFormTestMethodIdChange = (event) => {
        setFormTestMethodId(event.target.value)
        setTestId(event.target.value)
    };

    const handleDepartmentsChange = (event) => {
        setDepartment(event.target.value)
    };

    const handleTestMethodNameChange = (event) => {
        setTestMethodName(event.target.value)
    };

    const handleTestMethodNameEnglishChange = (event) => {
        console.log(openEdit)
        if (!openEdit) {
            handleTestMethodNameChange(event);
        }
        setTestMethodNameEnglish(event.target.value);
    };

    const handleTestMethodNameFrenchChange = (event) => {
        setTestMethodNameFrench(event.target.value);
    };

    const handleTestMethodNameSpanishChange = (event) => {
        setTestMethodNameSpanish(event.target.value);
    };
    
    const handleTestMethodNameItalianChange = (event) => {
        setTestMethodNameItalian(event.target.value);
    };
    
    const handleTestMethodNameArabicChange = (event) => {
        setTestMethodNameArabic(event.target.value);
    };
    
    const handleTestMethodNameRussianChange = (event) => {
        setTestMethodNameRussian(event.target.value);
    };

    const handleInternalSOPNameChange = (event) => {
        setInternalSOPName(event.target.value)
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

	const handleSequenceChange = (event) => {
	    setSequence(event.target.value);
	};

    const handleDefaultSamplesChange = (event) => {
        setDefaultSamples(event.target.value);
    };

    const handleCategoriesChange = (event) => {
        setCategories(event.target.value)
    };

    const handleIsAlsoTestChange = (event) => {
        setIsAlsoTest(event.target.checked)
        setTestFieldsVisible(event.target.checked);
        if (formTestMethodId) setTestId(formTestMethodId);
    }

    const handleTestDataTypeChange = (event) => {
        let dt = testDataTypes.find((x) => { return x.name === event.target.value })
        setTestDataType(dt);//event.target.value);
    };

    const handleUnitChange = (event) => {
        let description = event.target.value;
        let obj = units.find((u) => { return u.description === description })
        setUnit(obj);
    };

    const handleEquipmentsChange = (event) => {
        setEquipment(event.target.value)
    };

    const handleIsPass = (event) => {
        setIsPass(event.target.checked);
    };

    const handleTestIdChange = (event) => {
        setTestId(event.target.value);
    };

    const handleFileHeaderChange = (event) => {
        setFileHeader(event.target.value);
    }

    useEffect(() => {
        getDepartments().then((data) => {
            if (!(data instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            setDepartmentDetails(data);
        })
    }, []);


    useEffect(() => {
        const getCategoryInfo = async () => {
            let data = await getCategories(localStorage.getItem("language"));
            if (!(data instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            setCategoryDetails(data);
        }
        getCategoryInfo();



    }, []);


    useEffect(() => {
        if (openEdit === true) {
            console.log('selectedItems', selectedItems)
            let tmpDepartment = {};
            let departmentId = selectedItems.department.id
            let tmpCategories = []

            selectedItems.categories.forEach(category => {
                let temp = categoryDetails.find(c => c.id === category.id);
                if (temp) tmpCategories.push(category);
            })

            departmentDetails.forEach(depart => {
                if (departmentId === depart.id)
                    tmpDepartment = depart;
            });

            setTestMethodName(selectedItems.name)
            setTestMethodNameEnglish(selectedItems.nameEnglish)
            setTestMethodNameFrench(selectedItems.nameFrench)
            setTestMethodNameSpanish(selectedItems.nameSpanish)
            setTestMethodNameItalian(selectedItems.nameItalian)
            setTestMethodNameArabic(selectedItems.nameArabic)
            setTestMethodNameRussian(selectedItems.nameRussian)
            setIsAlsoTest(selectedItems.alsoTest)
            setDescription(selectedItems.description)
			setSequence(selectedItems.sequence)
            setInternalSOPName(selectedItems.sopCode)
            setFormTestMethodId(selectedItems.testMethodId)
            setDefaultSamples(selectedItems.defaultSamples)
            setDepartment(tmpDepartment)
            setCategories(tmpCategories)
        }
    }, [openEdit])

    useEffect(() => {
        const getEquipmentInfo = async () => {
            let data = await getEquipment();
            setEquipmentDetails(data);
            console.log(' equipmentDetails', equipmentDetails)
        }

        getEquipmentInfo();
    }, [openDialog])



    useEffect(() => {
        if (openEdit === true && isAlsoTest === true) {
            getTestByTestMethodId(selectedItems.id).then((data) => {
                if (data.length > 0) {
                    setTestFieldsVisible(true);
                    let test = data[0];
                    setTestDBId(test.id);
                    let tmpEquipment = [];
                    test.equipments.forEach(equipment => {
                        equipmentDetails.forEach(equip => {
                            if (equipment.id === equip.id) tmpEquipment.push(equip);
                        });
                    })
                    setEquipment(tmpEquipment);
                    let dt = testDataTypes.find((x) => { return x.name === test.testDataType })
                    setTestDataType(dt);
                   
                    setUnit(test.unit);
                    setTestId(test.testId);
                    setIsPass(test.pass);
                    setFileHeader(test.fileHeader);
                    test.calculationKey ? setCalculationKey(test.calculationKey) : setCalculationKey(null);
                    test.orderInCalculation ? setCalculationSeq(test.orderInCalculation) : setCalculationSeq(-1)
                }
            });
        }
    }, [isAlsoTest])

    useEffect(() => {
        if (openDialog) {
            console.log("-------> " + JSON.stringify(selectedItems, null, 2))
            getUnits().then((res) => {
                if (res.req_error) {
                    alert(t("Failed to get list of available unit types!"))
                    return;
                }
                setUnits(res);
            })
        }
    }, [openDialog])


    const clearFields = () => {
        setAlertBox("");
        setTestMethodName("");
        setTestMethodNameEnglish("");
        setTestMethodNameFrench("");
        setTestMethodNameSpanish("");
        setTestMethodNameItalian("");
        setTestMethodNameArabic("");
        setTestMethodNameRussian("");
        setIsAlsoTest(false)
        setDescription("");
		setSequence("");
        setInternalSOPName("");
        setFormTestMethodId("");
        setDepartment([]);
        setCategories([]);
        setTestFieldsVisible(false);
        setTestDataType(null);
        setUnit({ description: "" });
        setUnits([]);
        setEquipment([]);
        setEquipmentDetails([]);
        setIsPass(false);
        setTestId([]);
        setTestDBId();
        setFileHeader();
        setCalculationKey();
        setCalculationSeq(-1);
        setDefaultSamples(6);
    };

    const handleAlertBox = (messages) => {
        setAlertBox(<Alert severity="warning">
            <AlertTitle>{t("Could not create Test Method")}</AlertTitle>
            {messages.map((message, index) => {
                return <li key={index}>{message}</li>
            })
            }
        </Alert>)
    };

    const handleSave = async () => {
        let alertMessages = [];

        if (testMethodName.length === 0) alertMessages.push(t("Provide a Test Method Name"))
        // if (testMethodId=== '') alertMessages.push('Provide an ID');
        if (categories.length === 0) alertMessages.push(t("Provide at least one category"));

        if (defaultSamples <= 0) alertMessages.push(t("Provide a default samples number greater than 0"));

        if (Object.keys(department).length === 0) alertMessages.push(t("Select a department"));

        if (formTestMethodId.length === 0) alertMessages.push(t("Provide a Test Method ID"));

        if (isAlsoTest) {
            if (testDataType.length === null) alertMessages.push(t("Select a Test Data Type"));
            if (!unit.description || unit.description.length === 0) alertMessages.push(t("Select a Unit Type"));
            if (testId.length === 0) alertMessages.push(t("Provide a Test ID"));
            if (equipments.length === 0) alertMessages.push(t("Provide at least one Equipment"));
        }

        if (openEdit) {
            if (await checkCode(selectedItems.id, formTestMethodId, "TestMethod")) {
                alertMessages.push(t("Test ID must be unique"));
            }
        }

        if (!openEdit) {
            if (await checkCode(null, formTestMethodId, "TestMethod")) {
                alertMessages.push(t("Test ID must be unique"));
            }
        }

        if (alertMessages.length !== 0) handleAlertBox(alertMessages);

        else {
            let res;
            if (openEdit) {
                res = await addTestMethod(selectedItems.id, testMethodName, isAlsoTest, description, internalSOPName, categories, department, formTestMethodId, defaultSamples, testMethodNameFrench, testMethodNameSpanish, testMethodNameItalian, testMethodNameArabic, testMethodNameRussian, testMethodNameEnglish, sequence)
                console.log(res.status)
                if (isAlsoTest && res.status === 200) {
                    let replica = true;
                    res = await addTest(null, testDBId, testMethodName, description, testDataType.name, unit, testId, equipments, fileHeader, calculationKey, calculationSeq, isPass, selectedItems.id, replica, testMethodNameFrench, testMethodNameSpanish, testMethodNameItalian, testMethodNameArabic, testMethodNameRussian, testMethodNameEnglish)
                    console.log(res.status)
                }
            }
            else {
                if (isAlsoTest) {
                    let replica = true;

                    const addNewTestMethod = async () => {
                        return await addTestMethod(null, testMethodName, isAlsoTest, description, internalSOPName, categories, department, formTestMethodId, defaultSamples, testMethodNameFrench, testMethodNameSpanish, testMethodNameItalian, testMethodNameArabic, testMethodNameRussian, testMethodNameEnglish, sequence)
                    }

                    res = await addNewTestMethod()
                        if (res.status === 200 || res.status === 201) {
                            let testMethodId = res.data.id;
                            res = await addTest(null, null, testMethodName, description, testDataType.name, unit, testId, equipments, fileHeader, calculationKey, calculationSeq, isPass, testMethodId, replica, testMethodNameFrench, testMethodNameSpanish, testMethodNameItalian, testMethodNameArabic, testMethodNameRussian, testMethodNameEnglish)
                                
                        }

                    
                }
                else {
                    res = await addTestMethod(null, testMethodName, isAlsoTest, description, internalSOPName, categories, department, formTestMethodId, defaultSamples, testMethodNameFrench, testMethodNameSpanish, testMethodNameItalian, testMethodNameArabic, testMethodNameRussian, testMethodNameEnglish, sequence)
                    console.log(res.status)
                }

            }
            updateTable();
            onDialogClose(res);
            clearFields();
        }
    };

    const handleCancel = () => {
        updateTable();
        onDialogClose();
        clearFields();
    };


    const useStyles = makeStyles((theme) => ({
        root: {
            flex: 1,
            backgroundColor: 'paper',

        },
        dialog: {
            width: '400px',
            display: 'flex'
        },
        dialogBox: {
            display: 'flex',
            alignItems: 'center'
        },
        dialogText: {
            flex: '0.4',
        },
        dialogInput: {
            flex: '0.5',

        },
        formControl: {
            margin: theme.spacing(2),
            flex: '0.5',
            maxWidth: 300,

        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        dialogCheckbox: {
            flex: '0.04'
        }
    }));

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" minHeight="xl">{/* sm, xs, md, lg, xl*/}
                <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_test_method") : t("add_test_method"))} {text} </DialogTitle>
                <DialogContent>
                    {alertBox}

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label={t("test_method_dialog_tab_1")} />
                            <Tab label={t("test_method_dialog_tab_2")} />
                        </Tabs>
                    </Box> 

                    <TabPanel value={tabValue} index={0}>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("test_method_name")}*
                            </Typography>

                            <TextField value={testMethodNameEnglish} on onChange={handleTestMethodNameEnglishChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("test_method_name")} />
                        </div>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("is_also_a_test")}
                            </Typography>
                            <Checkbox checked={isAlsoTest} onChange={handleIsAlsoTestChange} className={classes.dialogCheckbox} name="" color="primary" />
                        </div>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("description_field_label")}
                            </Typography>

                            <TextField value={description} onChange={handleDescriptionChange} className={classes.dialogInput} placeholder={t("enter_description_placeholder")} multiline rows={3} margin="dense" id="description" type="input" variant="outlined" required />
                        </div>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("test_method_internal_sop_field_label")}
                            </Typography>

                            <TextField value={internalSOPName} on onChange={handleInternalSOPNameChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" required placeholder={t("internal_sop_name")} />
                        </div>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("category_s")}*
                            </Typography>


                            <FormControl className={classes.dialogInput} variant='outlined' className={clsx(classes.formControl, classes.noLabel)}>
                                <SingleSelector width={275} isMulti={true} placeholder={t("type_to_find_categories")}
                                    value={categories.map((c) => { return { value: c, label: c.name } })}
                                    options={categoryDetails.map((c) => ({ value: c, label: c.name }))}
                                    handleSelect={(e) => {
                                        var temp = e.map((obj) => { return obj.value });
                                        setCategories(temp);
                                    }}>
                                </SingleSelector>
                            </FormControl>
                        </div>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                ID*
                            </Typography>

                            <TextField value={formTestMethodId} on onChange={handleFormTestMethodIdChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("department_label")}*
                            </Typography>

                            <FormControl className={classes.dialogInput} variant='outlined' className={clsx(classes.formControl, classes.noLabel)}>
                                <Select
                                    displayEmpty
                                    value={department}
                                    onChange={handleDepartmentsChange}
                                    input={<Input />}
                                    renderValue={(selected) => {
                                        if (!selected.id) {
                                            return <em>{t("select_a_department")}</em>;
                                        }

                                        return (<Typography>{selected.name}</Typography>)
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    {departmentDetails.map((department) => (
                                        <MenuItem key={department.id} value={department}>
                                            {department.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("default_samples")}*
                            </Typography>

                            <TextField value={defaultSamples ? defaultSamples : ""} on onChange={handleDefaultSamplesChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="number" variant="outlined" required placeholder={t("default_samples")} />
                        </div>
						<div className={classes.dialogBox}>
						    <Typography variant="body1" className={classes.dialogText}>
						        {t("Sequence")}
						    </Typography>

						    <TextField value={sequence} on onChange={handleSequenceChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" required placeholder={t("sequence")} />
						</div>

						
                        <div hidden={!testFieldsVisible} style={{ flex: '100%' }}>
                            <br />
                            <Divider />
                            <br />
                            <DialogTitle id="form-dialog-title">{(openEdit ? "Edit Test Details" : "Add Test Details")} </DialogTitle>
                            <Box className={classes.dialogBox}>
                                <Typography className={classes.dialogText} variant="body1">
                                    {t("Test Data Type")}*
                                </Typography>
                                <FormControl
                                    className={clsx(
                                        classes.formControl,
                                        classes.noLabel,
                                        classes.dialogInput
                                    )}
                                >
                                    <Select
                                        value={testDataType ? testDataType.name : ""}
                                        // disabled={editDisabled}
                                        onChange={handleTestDataTypeChange}
                                        displayEmpty
                                        className={classes.selectEmpty}
                                        inputProps={{ "aria-label": "Without label" }}>
                                        <MenuItem value="" disabled>
                                            <em>Select test data type...</em>
                                        </MenuItem>
                                        {testDataTypes.map((dt, idx) => (
                                            <MenuItem
                                                key={"dataType" + dt.name + idx}
                                                value={dt.name}
                                                style={getStyles(dt.name, testDataTypes, theme)}>
                                                <Tooltip title={
                                                    <div style={{ padding: "8px", fontSize: "12px" }}>
                                                        {dt.tooltip.map((line) => { return <div>{line}</div> })}</div>}>
                                                    <div>{dt.name}</div>
                                                </Tooltip>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className={classes.dialogBox}>
                                <Typography className={classes.dialogText} variant="body1">
                                    {t("units_label")}*
                                </Typography>
                                <FormControl
                                    className={clsx(classes.formControl, classes.noLabel, classes.dialogInput)}>
                                    <Select
                                        value={unit && unit.description ? unit.description : ''}
                                        disabled={!testFieldsVisible}
                                        onChange={handleUnitChange}
                                        displayEmpty
                                        className={classes.selectEmpty}
                                        inputProps={{ "aria-label": "Without label" }}>
                                        <MenuItem value="" disabled>
                                            <em>{t("Select")} {t("units_label")}...</em>
                                        </MenuItem>
                                        {units.map((unit, idx) => (
                                            <MenuItem
                                                key={"unit" + idx}
                                                value={unit.description}
                                                style={getStyles(unit.description, units, theme)}>
                                                {unit.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className={classes.dialogBox}>
                                <Typography className={classes.dialogText} variant="body1">
                                    {t("equipment_label")}*
                                </Typography>
                                <FormControl
                                    variant="outlined"
                                    className={clsx(
                                        classes.formControl,
                                        classes.noLabel,
                                        classes.dialogInput
                                    )}
                                >
                                    <Select
                                        multiple
                                        displayEmpty
                                        disabled={!testFieldsVisible}
                                        value={equipments}
                                        onChange={handleEquipmentsChange}
                                        input={<Input />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>{t("Select all that apply")}...</em>;
                                            }

                                            return (
                                                <div className={classes.chips}>
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value.id}
                                                            label={value.name}
                                                            className={classes.chip}
                                                        />
                                                    ))}
                                                </div>
                                            );
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ "aria-label": "Without label" }}
                                    >
                                        {equipmentDetails.map((equipment) => (
                                            <MenuItem key={equipment.id} value={equipment}>
                                                {equipment.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className={classes.dialogBox}>
                                <Typography className={classes.dialogText} variant="body1">
                                    ID*
                                </Typography>
                                <TextField
                                    // disabled={editDisabled}
                                    value={formTestMethodId}
                                    onChange={handleFormTestMethodIdChange}
                                    className={classes.dialogInput}
                                    // style={{ maxWidth: "20%" }}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type="input"
                                    variant="outlined"
                                    required
                                />
                            </Box>
                            <Box className={classes.dialogBox}>
                                <Typography className={classes.dialogText} variant="body1">
                                    {t("CSV Column Header")}
                                </Typography>
                                <TextField
                                    // disabled={editDisabled}
                                    value={fileHeader}
                                    onChange={handleFileHeaderChange}
                                    className={classes.dialogInput}
                                    // style={{ maxWidth: "20%" }}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type="input"
                                    variant="outlined"
                                    required
                                />
                            </Box>
                            <Box className={classes.dialogBox}>
                                <Typography className={classes.dialogText} variant="body1">
                                    {t("Calculation Key")}
                                </Typography>
                                <TextField

                                    value={calculationKey}
                                    onChange={(e) => { setCalculationKey(e.target.value); }}
                                    className={classes.dialogInput}
                                    // style={{ maxWidth: "20%" }}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type="input"
                                    variant="outlined"
                                    required
                                />
                            </Box>
                            <Box className={classes.dialogBox}>
                                <Typography className={classes.dialogText} variant="body1">
                                    {t("Calculation Sequence No.")}
                                </Typography>
                                <FormControl
                                    className={clsx(
                                        classes.formControl,
                                        classes.noLabel,
                                        classes.dialogInput)}>

                                    <Select
                                        value={calculationSeq}

                                        onChange={(e) => { setCalculationSeq(e.target.value); }}
                                        style={{ maxWidth: "33%" }}
                                        renderValue={(selected) => {
                                            if (selected < 1) return <em style={{ color: "grey" }}>{t("Select")}...</em>;
                                            else return <div> {selected} </div>;
                                        }}
                                        className={classes.selectEmpty}
                                        inputProps={{ "aria-label": "Without label" }}>


                                        {sequences.map((seq, idx) => (
                                            <MenuItem
                                                key={idx}
                                                value={seq}>
                                                {seq}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className={classes.dialogBox}>
                                <Typography
                                    className={classes.dialogText}
                                    style={{ flex: 0.43 }}
                                    variant="body1"
                                >
                                    {t("Pass / Fail")}
                                </Typography>
                                <Switch
                                    checked={isPass}
                                    // disabled={editDisabled}
                                    onClick={handleIsPass}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                />
                            </Box>
                            <hr style={{ marginTop: 30, marginBottom: 10 }}></hr>
                            <Box className={classes.dialogBox}>
                                <Typography
                                    className={classes.dialogText}
                                    style={{ flex: 0.52 }}
                                    variant="body1"
                                >
                                    {t("Procedure Documentation")}
                                </Typography>
                                <Button
                                    // disabled={editDisabled}
                                    variant="contained"
                                    component="label"
                                >
                                    {t("Upload File")}
                                    <input type="file" hidden />
                                </Button>
                            </Box>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("french_name")}
                            </Typography>

                            <TextField value={testMethodNameFrench} onChange={handleTestMethodNameFrenchChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("french_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("spanish_name")}
                            </Typography>

                            <TextField value={testMethodNameSpanish} onChange={handleTestMethodNameSpanishChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("spanish_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("italian_name")}
                            </Typography>

                            <TextField value={testMethodNameItalian} onChange={handleTestMethodNameItalianChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("italian_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("arabic_name")}
                            </Typography>

                            <TextField value={testMethodNameArabic} onChange={handleTestMethodNameArabicChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("arabic_name")} />
                        </div>

                        <div className={classes.dialogBox}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("russian_name")}
                            </Typography>

                            <TextField value={testMethodNameRussian} onChange={handleTestMethodNameRussianChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required placeholder={t("russian_name")} />
                        </div>
                    </TabPanel>
                    
                </DialogContent>
                <DialogActions>
                    <Tooltip title={t("save")}>
                        <IconButton onClick={handleSave} color="primary">
                            {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("cancel")}>
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TestMethodDialog