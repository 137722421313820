

import { Box, makeStyles, Button, TextField, Typography, Divider, Paper, Tabs, Tab, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import ReportsTable from '../Reports/ReportsTable'
import OpenReport from '../Reports/OpenReport'
import { useStyles } from "../Reports/styles"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@material-ui/core/IconButton';


const Reports = (props) => {
    const classes = useStyles();
    const history = props.history;
    const { t } = useTranslation();
    const [selectedReport, setSelectedReport] = useState(null)
    const [dense, setDense] = useState(true);

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };
   
    // Page identifiers
    const reportsTables = 0;
    const openReport = 1;
   

    const [page, setPage] = useState({ id: -1, component: null });
    
    let showOpenReport = false;
    
    // Handles which page to show based on URL query params.
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        showOpenReport = params.get("open") === "true";

        if (page.id === reportsTables ) setSelectedReport(null);

        if (showOpenReport) setPage({ id: openReport, component: <OpenReport dense={dense} setDense={setDense} history={history} setSelectedReport={setSelectedReport} /> });

        else setPage({id: reportsTables,component: <ReportsTable dense={dense} history={history} />});

    }, [window.location.search, dense])

    // <Button variant="contained" onClick={() => { history.goBack(); }}>{"< Back"}</Button>
    return (
        <div className={classes.root}>
            <Box mx={2} className={classes.pageHeading}>
                <div className={classes.title}>
                    {page.id > reportsTables ? <IconButton onClick={() => { history.goBack(); }}> <ArrowBackIcon /> <span style={{ fontSize: "small" }}> { t('back')}</span> </IconButton> : null}
                </div>
                <Typography className={classes.title} style={{ fontWeight: 550, fontSize: "20px" }}>
                    {page.id === 0 ? t("reports_title") : selectedReport !== null ? 
                        <>{selectedReport.jobID ? t("Report for") + ": " + selectedReport.jobID : t("Report for") + ": " + selectedReport.name}</> : null}
                </Typography>
                <div style={{ width: page.id === reportsTables  ? "120px" : "450px" }}></div>
            </Box>
            {page.component}
            {/*<FormControlLabel*/}
            {/*    control={<Switch checked={dense} onChange={handleChangeDense} />}*/}
            {/*    label="Dense padding" />*/}
         </div>
    );
}

export default withRouter(Reports);