
import { Box, Button, Typography} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect} from 'react';
import { getJobById } from '../../services/RegisterJobService';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpVideoDialog from '../Dialogs/HelpVideoDialog'
import "react-table-6/react-table.css";

import IDocQueueTable, { IDocQueueDialog } from '../Testing/IDocQueueTable'
import JobsTable from '../Testing/JobsTable';
import AllQueuesTable from '../Testing/AllQueuesTable';
import JobTestsTable from '../Testing/JobTestsTable';
import SweepTestsTable from '../Testing/SweepTestsTable';
import OpenSessionTable from '../Testing/OpenSessionTable';
import SessionsHistoryTable from '../Testing/SessionsHistoryTable';
import { useStyles } from '../Testing/Styles';
import UserService from '../../keycloak'


// Main route component =============================================================================================================================================== //

// Handles which table to show based on user navigatiuon.

function Testing(props) {

    const classes = useStyles();
    const { t } = useTranslation();
    const history = props.history;

    
    const [job, setJob] = useState(null);
    const [table, setTable] = useState({ id: -1, component: null });
    const [idocsDialogShown, setIdocsDialogShown] = useState(false);
   

    // Table identifiers
    const jobsTable = 0;
    const testsTable = 1;
    const iDocsTable = 2;
    const openSessionTable = 3;
    const sessionsHistoryTable = 4;
    const allQueuesTable = 5;
    const sweepTestsTable = 6;
    
    // Vars to determine which table to show...
    let showOpenSessionTable = false;
    let showIDocsTable = false;
    let showTestsTable = false;
    let showSessionsHistoryTable = false;
    let showAllQueuesTable = false;

    let showSweepTestsTable = false;


    // Handles which table to show based on URL query params.
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        showIDocsTable = params.get("iDocsTable") === "true";
        showTestsTable = params.get("testsTable") === "true";
        showOpenSessionTable = params.get("openSessionTable") === "true";
        showSessionsHistoryTable = params.get("sessionsHistoryTable") === "true";
        showAllQueuesTable = params.get("allQueuesTable") === "true"
        showSweepTestsTable = params.get("sweepTestsTable") === "true";
        if (showTestsTable) getJobById(params.get("jobId")).then(job => setJob(job))

        if      (showIDocsTable)           setTable({ id: iDocsTable, component: <IDocQueueTable history={props.history} classes={classes} />});
        else if (showTestsTable)           setTable({ id: testsTable, component: <JobTestsTable history={props.history} classes={classes} /> });
        else if (showOpenSessionTable)     setTable({ id: openSessionTable, component: <OpenSessionTable history={props.history} classes={classes} /> })
        else if (showSessionsHistoryTable) setTable({ id: sessionsHistoryTable, component: <SessionsHistoryTable history={props.history} classes={classes}  /> })   //
        else if (showAllQueuesTable)       setTable({ id: allQueuesTable, component: <AllQueuesTable history={props.history} classes={classes} />})
        else if (showSweepTestsTable)      setTable({ id: sweepTestsTable, component: <SweepTestsTable history={props.history} classes={classes} />})
        else setTable({ id: jobsTable, component: <JobsTable history={props.history} classes={classes}  />});
    }, [window.location.search ])



   

    // Show jobs table
    const handleJobsClick = () => {
        history.push("/testing")
    }

    // Show iDoc table
    const handleIdocsClick = () => {
        history.push("/testing/?iDocsTable=true")
    }

    // Show queue items for all users table
    const handleAllQueuesClick = () => {
        history.push("/testing/?allQueuesTable=true")
    }


    // Navigation menu buttons
    const testingMenu = (
        <div style={{ textAlign: "center", marginBottom: "10px" }}>

            <Button onClick={table.id !== testsTable ? handleJobsClick : () => { }}
                 size="small"
                 className={table.id === jobsTable || table.id === testsTable || table.id === sweepTestsTable ? classes.navButton2 : classes.navButton1}>
                 {table.id===testsTable ? `Job: ${job !==null? job.jobID: ""}`:t("jobs_label")}
            </Button>

            <Button onClick={handleIdocsClick} style={{marginLeft:"5px"}}
                 size="small"
                className={table.id === iDocsTable ? classes.navButton2 : classes.navButton1}>
                {t("idoc_work_queue_label")}
            </Button>

            { UserService.hasRole(['ADMIN', 'LAB_MANAGER', 'LAB_SUPERVISOR']) ?
                <Button onClick={handleAllQueuesClick} style={{ marginLeft: "5px" }}
                    size="small"
                    className={table.id === allQueuesTable ? classes.navButton2 : classes.navButton1}>
                    {t("Queue Items - All Users")}
                </Button> : null
            }
        </div>
    )

    const handleOpenSessionTableClick = () => {
        if (table.id === sessionsHistoryTable) props.history.goBack();
    }

    const handleSessionHistoryTableClick = () => {
        history.push("/testing/?sessionsHistoryTable=true")
    }


    const sessionsMenu = (
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <Button onClick={handleOpenSessionTableClick}
                size="small"
                className={table.id === openSessionTable ? classes.navButton2 : classes.navButton1}>
                {t("Open Session")}
            </Button>
            <Button onClick={handleSessionHistoryTableClick} style={{ marginLeft: "5px" }}
                size="small"
                className={table.id === sessionsHistoryTable ? classes.navButton2 : classes.navButton1}>
                {t("Sessions History")}
            </Button>
        </div>
    )
   
    return (
      
        <div className={classes.root}>
            <Box mx={2} className={classes.pageHeading}>
                <div className={classes.title}>
                    {table.id > 0 ? <IconButton onClick={() => { props.history.goBack(); }}> <ArrowBackIcon /> <span style={{ fontSize: "small" }}>{t('back') }</span> </IconButton>: null}
                </div>
              
                <Typography className={classes.title} style={{ fontWeight: 550, fontSize: "20px" }}>{t("testing_title")}</Typography>

                {table.id === 3 || table.id === 4 ?
                    <Button Button variant="outlined" onClick={() => { setIdocsDialogShown(true) }}>{"view " + t("idoc_work_queue_label")}</Button> :
                    <div style={{ width: "120px" }}></div>
                }
            </Box>

            {table.id === 0 || table.id === 1 || table.id === 2 || table.id === 5 ? testingMenu : null}
            {table.id === 3 || table.id === 4 ? sessionsMenu : null}
            <div style={{marginBottom:"10px"}}><HelpVideoDialog topic={"Testing & Reports"} /></div>
            {table.component}
          
           
            <IDocQueueDialog history={props.history} classes={classes}  open={idocsDialogShown} onClose={() => {setIdocsDialogShown(false)}}/>
         </div>      
     
    );
}


export default withRouter(Testing);
