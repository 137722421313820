/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-unreachable */

import { Box, Button, Typography, Divider } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { getJobById, getSweepJobById } from '../../services/RegisterJobService';
import {  getIDocsInJob,addIdocToQueue, remIdocFromQueue, getIDocsInSweepSet } from '../../services/iDocWorkQueService'
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import FilledDotIcon from '@mui/icons-material/FiberManualRecord';
import OutlinedDotIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FailIcon from '@mui/icons-material/Close';
import PassIcon from '@mui/icons-material/Check';
import DashIcon from '@mui/icons-material/HorizontalRule';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import AddQueIcon from '@mui/icons-material/AddCircleOutline';
import RemoveQueIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import ViewIcon from '@mui/icons-material/Visibility';
import ImportIcon from '@mui/icons-material/UploadFile';
import { uploadResultsFile } from '../../services/FileService'
import OKDialog from "../Dialogs/OKDialog";
import { getJobStates, parseDate } from './HelperFunctions';
import "react-table-6/react-table.css";
import { getSweepSetsByJob} from '../../services/RegisterJobService';
import { makeStyles } from '@material-ui/core/styles';
// import { , downloadReport, saveJobComments} from '../../services/RegisterJobService';



const SweepTestsTable = (props) => {

    const classes = props.classes;
    const { t } = useTranslation();
   
    const [job, setJob] = useState(null);
    const [rows, setRows] = useState([]);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const history = props.history;
    const [timeoutElapsed, setTimeoutElapsed] = useState(false);
    const fileInputRef = useRef();
    const [resultsFile, setResultsFile] = useState(null);

    const [sweepSets, setSweepSets] = useState([]);
    const [selectedSetId, setSelectedSetId] = useState(null);
    const [active, setActive] = useState(null);
    const [sweep, setSweep] = useState(null);
    const [loading, setLoading] = useState(false);
   
    const [sessionId, setSessionId] = useState();

    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],

    }));

    const useStyles = makeStyles(theme => ({
        button: {
            opacity: 0.5,
        },
        buttonActive: {
            opacity: 1,
        }
    }));

    const buttonClasses = useStyles();

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })

    const parseJob = (j) => {
       
        if (j.addedBy == null) j.addedBy = ""
        j.addDate = parseDate(j.addDate, 'DD/MM/YYYY')
        j.updatedAt = parseDate(j.updatedAt, 'DD/MM/YYYY @ HH:mm')
        j.dueDate = parseDate(j.dueDate, 'DD/MM/YYYY')

        if (j.client && j.client.name && (j.client.name !== null)) j.clientName = j.client.name
        else j.clientName = "<unknown>"
        setJob(j);
    }


    // const poller = useRef(null);

    // This following link explains how setInterval works and what should be done to fix the callback that does not use fresh values each time it calls the function: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    // --------------------------------------------------------------
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = populateTable;
    });

    useEffect(() => {
        populateTable();
        // poller.current = setInterval(() => { populateTable() }, 6000);
        let poller = setInterval(() => { 
            // populateTable() 
            savedCallback.current();
        }, 6000);
        return () => { clearInterval(poller) }
    }, [])

    // -----------------------------------------------------------------


    
    useEffect(() => {
        setLoading(true);
        
		console.log("USE EFFECT selectedSetId", selectedSetId);
	    if (job !== null && selectedSetId > 0) {
            getIDocsInSweepSet(job.id, selectedSetId, localStorage.getItem("language")).then((res) => {
                if (res.req_error) {
                    setAlertContext({ ...alertContext, open: true });
                    return;
                }
                res.sort((a, b) => sequenceCompare(a, b));
                res.forEach((r, i) => { r.tabValue = i + 1; })
                setRows(res);
                setLoading(false);
            });// end of function
        }
		

	}, [selectedSetId])

    useEffect(() => {
        // setLoading(true);
		if (job !== null) getSweepSets(job.id);

	}, [job])


    // Helper function for sorting iDocs by their sequence attribute.
    const sequenceCompare = (a, b) => {
        if (a.sequence < b.sequence) return -1;
        else if (a.sequence > b.sequence) return 1;
        else return 0;
    }

    const getSweepSets = (id) => {
        getSweepSetsByJob(id).then((res) => {
           if (res === false) {
               setAlertContext({ ...alertContext, open: true });
               return;
           }
           setSweepSets(res);

            if (!selectedSetId && !active) {
                setSelectedSetId(res[0].id);
                setActive(res[0].sequence);
            }

           setLoading(false);
       })
   };

    const populateTable = () => {
        setLoading(true);
        // setTimeoutElapsed(false);
        setTimeoutElapsed(true);

        // setTimeout(() => { setTimeoutElapsed(true) }, 6000);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('jobId')


        // if (job === null)  {
            getSweepJobById(id).then((res) => {
                if (res === false) {
                    setAlertContext({ ...alertContext, open: true });
                    return;
                }
                parseJob(JSON.parse(JSON.stringify(res)));
                // setJob(res);
                // setSelectedReport(res);
                // setJobLoaded(true);    
                // setLoading(false);
                
                getSweepSets(res.id);
            });
        // }

        if (job!=null && selectedSetId > 0) {
            getIDocsInSweepSet(job.id, selectedSetId, localStorage.getItem("language")).then((res) => {
                if (res.req_error) {
                    setAlertContext({ ...alertContext, open: true });
                    return;
                }
                res.sort((a, b) => sequenceCompare(a, b));
                res.forEach((r, i) => { r.tabValue = i + 1; })
                setRows(res);
                setLoading(false);
            });// end of function
        }
        // } 
        
    }


    const getState = (j) => {
        if (j === null) return ""
        return <span id={j.state} style={{
            border: "1px solid",
            backgroundColor: getJobStates(t)[j.state].color,
            padding: "2px"
        }}>{" " + getJobStates(t)[j.state].text}
        </span>
    }



    const getActionButton = (row, typ) => {
		
		console.log("ACTION BTN "+typ+" "+row);
        const viewResultsBtn = (
            <Tooltip followCursor={true} title="View">
                <IconButton
                    onClick={() => {
                        var url = `/testing/?openSessionTable=true&jobId=${job.id !== null ? job.id : null}&iDocId=${row.id}&iDocTestMethod=${row.testMethod}`
                        history.push(url)
                    }}
                >
                    <ViewIcon />
                </IconButton >
            </Tooltip>
        );

        const addToQueueBtn = (
            <Tooltip followCursor={true} title={getAddTooltip(typ)}>
            <IconButton
                onClick={() => {
                    addIdocToQueue(row.id, typ).then((res) => {
                        populateTable()
                    });
                }} >
                { getIcon(typ)		}
            </IconButton>
            </Tooltip>
        )


        const addedToQueueBtn = (
            <Button disabled className={classes.rowButton}>
                added to queue
            </Button>
        )


        const remFromQueueBtn = (
            <IconButton className={classes.rowButton}
                onClick={() => {
                    remIdocFromQueue(row.id).then((res) => {
                        populateTable();
                    })
                }}>
                <RemoveQueIcon />
            </IconButton>
        )



        var btn = (<div></div>);
        switch (row.status) {
            case "received": case "removed":
                btn = addToQueueBtn;
                break;
            case "on_hold":
                btn = addedToQueueBtn;
                break;
            case "completed_pending": case "completed_accepted":
                if (typ==0) btn = viewResultsBtn;
                break;
            case "in_progress":
                if (typ==0) btn = viewResultsBtn; //remFromQueueBtn;
                break;

            default:
                break;
        }
        return btn;
    }// geet action button


const getIcon= (typ) => {
	if (typ==0) return <AddQueIcon />
	if (typ==1) return <DriveFolderUploadOutlinedIcon />
	if (typ==2) return <HandymanOutlinedIcon />
	
}
const getAddTooltip= (typ) => {
	if (typ==0) return "Add to IDoc Queue";
	if (typ==1) return "Add to Importing Results Queue";
	if (typ==2) return "Add to Manual Entry Queue";
	
}



    const getCompleted = (row) => {
        var status = row.status;
        //var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        //if (progress >= 100) status = "completed"
        switch (status) {
            case "on_hold":
                return (<FilledDotIcon style={{ color: "yellow" }} />);
            case "completed_pending": case "completed_accepted": case "completed":
                return (<FilledDotIcon style={{ color: "green" }} />);
            default:
                return (<OutlinedDotIcon style={{ color: "red" }} />);
        }
    }

    const getPassed = (row) => {
        switch (row.result) {
            case "passed":
                return (<PassIcon style={{ color: "green" }} />)
            case "rejected":
                return (<FailIcon style={{ color: "red" }} />)
            case "received": case "pending": case "removed":
                return (<DashIcon />)
        }
    }
    const getTooltipText = (row) => {
        var line1 = row.testsCompleted + " " + t("out of") + " " + (row.numberOfTests === null ? "NaN" : row.numberOfTests) + t("tests completed") + ".";
        var line2 = ` - ${row.testsPassed} tests passed.`
        var line3 = ` - ${row.testsCompleted - row.testsPassed} tests failed or ignored.`
        return (<div style={{ fontSize: "12px" }}>{line1}<br />{line2}<br />{line3}</div>)
    }

    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        return (
            <Tooltip followCursor={true} title={getTooltipText(row)}>
                <div style={{ minWidth: "100px" }}>
                    {progress + "% " + t("complete")}
                    <CustomLinearProgress variant="determinate" value={progress} />
                </div>
            </Tooltip>
        )
    }


    const getImportResultsButton = (row) => {
        return (
            <>
                <Tooltip followCursor={true} title={t("Import")}>
                    <IconButton onClick={() => {
                        setSessionId(row.id);
                        fileInputRef.current.click();
                        //document.getElementById("input_" + row.testMethod).click();
                    }}>
                        < ImportIcon />
                    </IconButton>
                </Tooltip>
                <input type="file" onChange={(e) => { setResultsFile(e.target.files[0]); }}
                    onClick={(event)=> { event.target.value = null}}
                    style={{ display: 'none' }}
                    ref={fileInputRef} />
            </>
        )
    }

    // Either the file could not be uploaded due to a network problem or it could not be parsed. Please check network connection and that file is valid.
    useEffect(() => {
        if (resultsFile !== null) {
            uploadResultsFile(sessionId, resultsFile).then((res) => {
                //alert("----> upload result: " + JSON.stringify(res, null, 2))

                //Endpoint may return error value (getValue) as alert instead of ending processing
                //Some cases the value can be null, just inform user that file did not have enough values for process
                if (!res.req_error) {
                    if (res.value !== null && res.value.startsWith("Partly imported results")) {
                        setOKDialogState({
                            shown: true,
                            title: t("Successfully Imported!"),
                            message: t("The results file has been parsed and uploaded!"),
                            flavour: "success",
                            callback: (r) => {
                                setOKDialogState({
                                    shown: true,
                                    title: t("Import Alert!"),
                                    message: res.value,
                                    flavour: "info",
                                    callback: (r) => {
                                        setResultsFile(null)
                                    }
                                })
                            }
                        })
                    } else {
                        setOKDialogState({
                            shown: true,
                            title: t("Successfully Imported!"),
                            message: t("The results file has been parsed and uploaded!"),
                            flavour: "success",
                            callback: (r) => {
                                setResultsFile(null)
                                // setIsResultsFilePicked(false);
                            }
                        })
                    }
                }
                else {
                    setOKDialogState({
                        shown: true,
                        title: t("Importing failed!"),
                        message: res.req_error,
                        flavour: "error",
                        callback: (r) => {
                            setResultsFile(null)
                        }
                    })
                }
            })
        }
    }, [resultsFile]) // [isResultsFilePicked])


    const getCols = () => {
        const cols = [
            { selector: row => row.testMethod, sortable: false,  style: {minWidth:"32%",width: "40% !important"},     type: "text", key: "testMethod" },
//            { selector: row => getEquipmentNames(row), sortable: false, name: t("equipment_label"), type: "text", key: "equipmentNames" },
            // { selector: row => getDataEntryMethod(row), sortable: false, name: t("data_entry_label"), type: "text", key: "dataEntryMethod" },
            { selector: row => getProgress(row), sortable: false, style: {maxWidth:"23%"},type: "text", key: "progress" },
//            { selector: row => getCompleted(row),style: {maxWidth:"6%"},sortable: false, type: "text", key: "completed" },
            { selector: row => getPassed(row),  style: {maxWidth:"6%"},sortable: false, type: "text", key: "passed" },
            { selector: row => getImportResultsButton(row), style: {textAlign:"center", maxWidth:"6%" },sortable: false, key: "importResults" },
            { selector: row => getActionButton(row,0), style: {textAlign:"center", maxWidth:"6%"}, sortable: false, key: "action"},
            { selector: row => getActionButton(row,1), style: { textAlign:"center",maxWidth:"6%"},  sortable: false, key: "action" },
            { selector: row => getActionButton(row,2), style: { textAlign:"center",maxWidth:"6%"}, sortable: false, key: "action"},
        ]
        return cols;
    }


    return (
        <div>
            <Paper className={classes.paper}>
                <Box mx={2} className={classes.tableHeader}>
                    <Typography
                        className={classes.title}
                        style={{ fontWeight: 400, fontSize: "22px" }}>
                        {`${t('Job')}: ${job !== null && job.name ? job.name : "<id unknown>"}`}
                    </Typography>
                    {job !== null ? (
                        <div>
                            <table style={{ width: "80%" }}>
                                <tbody>
                                    <tr>
                                        <td>{t('Added by')}:<b> {job.updatedBy}</b></td>
                                    </tr>
                                    <tr>
                                        <td>{t('Date added')}:<b> {job.addDate}</b></td>
                                    </tr>
                                    <tr>
                                        <td>{t('Due date')}:<b> {job.dueDate}</b></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table width="100%" border="0" >
                                <tbody>
                                    <tr>
                                    <td  >Set:</td>
                                    <div style={{display: "flex", flexDirection:"row"}}>
                                        {
                                            sweepSets.map((tm) => {
                                                return(
                                                    <td style={{margin:"2px"}}>
                                                            <Button key={tm.id} variant="contained" className={active === tm.sequence ? buttonClasses.buttonActive : buttonClasses.button} onClick={() => {
                                                                setLoading(true);
                                                                setSelectedSetId(tm.id);
                                                                setActive(tm.sequence);
                                                                // backToSummary();
                                                            }}>
                                                                {tm.sequence}
                                                            </Button>
                                                    </td>
                                                    )
                                                })
                                        }
                                    </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    ) : null}

                </Box>
                <Divider orientation="horizontal" style={{ marginTop: "20px" }}></Divider>
                {rows.length > 0 || timeoutElapsed === true ?
                    <DataTable id="iDocTable2"
                        customStyles={{
                            table: { 
//								"tableLayout":"fixed",
								style: { 
										width: "100%" , 
										display: "inline-flex",
                                    } ,

								},
                            headCells: {
                                style: {
                                    "fontWeight": 'bold',
                                    "fontSize": "12px",
                                    "textAlign":"right",	
                                },
                            }
                        }}
                        pagination
                        paginationPerPage={90}
                        paginationRowsPerPageOptions={[30, 60, 90, 120]}
                        striped
                        columns={getCols()}
                        data={rows}
                        
                        // noDataComponent={<div style={{ margin: "50px", fontSize: "18px" }}>No results! Will retry...</div>}
                        noDataComponent={loading === false && <div style={{ margin: "50px", fontSize: "18px" }}>No results!</div>}
                        clearSelectedRows={() => { }}
                        paginationComponentOptions={{
                            rowsPerPageText: t("rows_per_page_label"),
                            rangeSeparatorText: t("range_seperator_label"),
                        }} />
                    : <div></div>}

                    {loading && 
                        <div style={{ textAlign: "center", marginBottom: "20px" }}><p>{t("Loading table data")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>
                    }
                
            </Paper>
            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
        </div>

    )
}

export default SweepTestsTable