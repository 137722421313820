import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'testMethod'


const getTestMethodsByCategoryId= async (id) => {
  let language = localStorage.getItem("language");
  try {
    const res = await axios.get(`${baseURL}${rootRequestMapping}/category/${id}/${language}`);
    const data = await res.data;

    return data;
  } catch (error) {
    return null;
  }
}


const getTestMethods = async (language) => {
  try {
    const res = await axios.get(`${baseURL}${rootRequestMapping}/all/${language}`);
    const data = await res.data;

    return data;
  } catch (error) {
    return null;
  }
}



const moveQueueItemUp = async (rowID) => {
  try {
    console.log(rowID)
      const req = {
          url: `${baseURL}${rootRequestMapping}/test/up/${rowID}`,
          method: methods.GET,
      }
      const res = await axios(req);
      return res.data;

  } catch (err) {
      return { req_error: err }
  }
}


const moveQueueItemDown = async (rowID) => {
  try {
      const req = {
          url: `${baseURL}${rootRequestMapping}/test/down/${rowID}`,
          method: methods.GET,
      }
      const res = await axios(req);
      return res.data;

  } catch (err) {
      return { req_error: err }
  }
}



const addTestMethod = async (id, name, isAlsoTest, description, internalSOP, categories, department,formTestMethodId, defaultSamples, testMethodNameFrench, testMethodNameSpanish, testMethodNameItalian, testMethodNameArabic, testMethodNameRussian, testMethodNameEnglish, sequence) => {
 console.log('formTestMethodId',formTestMethodId)
 let language = localStorage.getItem("language");
  try { 
    let data = {
      "name": name,
      "alsoTest":  isAlsoTest,
      "description": description,
      "sopCode": internalSOP,
      "categories": categories,
      "department": department,
      "testMethodId": formTestMethodId,
      "defaultSamples": defaultSamples,
      "nameEnglish": testMethodNameEnglish,
      "nameFrench": testMethodNameFrench,
      "nameSpanish": testMethodNameSpanish,
      "nameItalian": testMethodNameItalian,
      "nameArabic": testMethodNameArabic,
      "nameRussian": testMethodNameRussian,
	  "sequence":sequence
    }

    if (id !== undefined && id !== null)
    data["id"] = id;

    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/save/${language}`,
      method: methods.POST,
      data: data
    });
    
      
    // if (res.status === 200 || res.status === 201)
    return res;

    // return false;
  }

  catch (error) {
    console.error(error)
    return error;
  }
}

const deleteTestMethod = async id => {
  try{
    let res = await axios.request({
      url: `${baseURL}${rootRequestMapping}/delete/${id}`,
      method: methods.GET,
    })
    console.log(res)
  }
  catch(error){
    console.error(error);
  }
  return false;
}

const checkCode = async (id, code, entity) => {
   
  try {
    let res = await axios.post(`${baseURL}uniquecheck/check`, {
      "id": id,
      "code": code,
      "entity": entity
    })
    console.log(res)
    return res.data;
  }

  catch (error) {
    console.error(error)
  }
}

const deleteTest = async id => {
  try{
    let res = await axios.request({
      url: `${baseURL}test/delete/${id}`,
      method: methods.GET,
    })
    console.log("success: " + res)
  }
  catch(error){
      console.error("fail: " + error);
  }
  return false;
}


export { getTestMethods, addTestMethod, deleteTestMethod, getTestMethodsByCategoryId, moveQueueItemDown, moveQueueItemUp, deleteTest, checkCode }