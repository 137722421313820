import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = 'sweepset'

// async function getAllSweepJobs() {
//     let language = localStorage.getItem("language");
//     try {
//         let res = await axios.request({
//             url: `${baseURL}${rootRequestMapping}/all/${language}`,
//             method: methods.GET
//         });

//         return (res.status === 200) ? res.data : false;
//     }
//     catch (error) {
//         console.error(error);
//         return null;
//     }
// }


async function getSweepSetsByJobId(id){
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/sets/${id}`,
            method: methods.GET
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}

const saveSweepSetComments = async (comment, setId) => {
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/comments/${setId}`,
            method: methods.POST,
            data: { text: comment}
        });

        return (res.status === 200) ? res.data : false;
    }
    catch (error) {
        console.error(error);
        return false;
    }

}

// async function addJob(data) {
//     let language = localStorage.getItem("language");
//     try {
//         if(data.state == null)  data.state = "Received";
//         if (data.jobChanged) delete data.jobChanged
//         let res = await axios.request({
//             url: `${baseURL}${rootRequestMapping}/${language}`,
//             method: methods.POST,
//             data: data,
//         });

//         return (res.status === 200) ? res.data : false;
//     }
//     catch (error) {
//         console.error(error);
//         return false;
//     }
// }

const saveSweepSet = async (data) => {
    // let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/save`,
            method: methods.POST,
            data: data
        });

        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}

const saveSweepSetSamples = async (data) => {
    // let language = localStorage.getItem("language");
    try {
        let res = await axios.request({
            url: `${baseURL}${rootRequestMapping}/savesamples`,
            method: methods.POST,
            data: data
        });

        return res.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}



export {
    saveSweepSet, getSweepSetsByJobId, saveSweepSetSamples, saveSweepSetComments
};