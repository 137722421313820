import { Box, makeStyles, Button, IconButton, TextField, Typography, Divider, Paper, Tabs, Tab, FormControlLabel, Switch, Tooltip, CircularProgress } from '@material-ui/core';
import UploadIcon from '@mui/icons-material/Upload';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import image_placeholder from "../../images/diagram_placeholder.bmp"
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useStyles } from "./styles";
import { uploadPreformDrawingFile, getSavedFile } from '../../services/FileService';
import { addSampleTypeFromJob } from '../../services/SampleTypesService';
import OKDialog from "../Dialogs/OKDialog";
import { useTranslation } from 'react-i18next';

const DiagramComponent = (props) => {

    const [diagramShown, setDiagramShown] = useState(true)
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState(null)
    const [imageKey, setImagekey] = useState("");
    const [diagramName, setDiagramName] = useState("");
    const [selectedFile, setSelectedFile] = useState([]);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const fileInput = React.useRef();
    const job = props.job;
    const { t } = useTranslation();

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
        })

    const showHideDiagram = (e) => {
        setDiagramShown(e.target.checked);
    }

    const fileChangeHandler = (event) => {
        var file = event.target.files[0];
        var fileExt = file.name.split('.').pop();
        var name = event.target.files[0].name;
        setSelectedFile([file, fileExt]);
        setIsFilePicked(true);
        setDiagramName(name);
    };


    useEffect(() =>{
        if (isFilePicked) {
            
            uploadPreformDrawingFile(selectedFile[0]).then((res) => {
                if (res){
                    let base64ToString = Buffer.from(res.image, "base64").toString()
                    let image = `data:image/jpeg;base64,${base64ToString}`; 
                    setImageUrl(image)
                    setImagekey(res.key);
                   
                } 
                
            }).catch((err) => { })
        }
    }, [isFilePicked]);

    const handleSubmission = async () => {
        // save sampletype
        let amendedSampleType = job.sampleType;
        amendedSampleType.preformDrawingPath= imageKey;
        amendedSampleType.diagramFilename = diagramName;

        let res = await addSampleTypeFromJob(amendedSampleType)
        handleStatusCode(res);
    }

    useEffect(() => {
        if(!isFilePicked){
        const getImage = async () =>{
            let data = await getSavedFile(job.sampleType.preformDrawingPath);
            console.log(JSON.stringify("preform img path: " +  job.sampleType.preformDrawingPath , null, 2))
            if(data){
              let image = `data:image/jpeg;base64,${data}`;        
                setImageUrl(image);
            }
          }
          getImage();
        }
    }, [isFilePicked]);

    const handleStatusCode = (event) =>{
        switch (event.status){
          case 200:
            setIsFilePicked(false);
            setDiagramShown(true);
            setOKDialogState({
              shown: true, 
              title: t("Saved"),
              message:  event.data.name + t("Preform Drawing Updated"),
              flavour: "success", 
              // callback: revisitPage1
            });
            break;
          default:
            setOKDialogState({
              shown: true, 
              title: t("Fail"),
              message:  t("failed to save, try later"),
              flavour: "error", 
              // callback: revisitPage1
            });
            break;
           
        }
      };

    return (
        <div>
            <Paper className={classes.paper} style={{ width: "100%", padding: "25px", textAlign: "center" }}>
                <table>
                    <tr>
                        <td>
                            <Tooltip followCursor={true} title={t("Select an image file to upload.")}>
                                <IconButton variant="contained" onClick={() => fileInput.current.click()}><FolderOpenIcon /></IconButton>
                            </Tooltip>
                        </td>
                        <td style={{ width: "200px" }}><span ><b>{t('Diagram')}</b></span></td>
                        <td><Tooltip followCursor={true} title={t("Show / Hide")}>
                            <Switch checked={diagramShown} onChange={showHideDiagram} />
                        </Tooltip></td>
                    </tr>
                </table>
                {diagramShown ?
                    <div style={{ textAlign: "center" }}>
                        <img style={{ border: "1px solid" }}
                            id="diagram" width="150" height="200"
                            src={imageUrl ? imageUrl : image_placeholder}
                            alt={t("<No image to show, please upload one.>")} />
                    </div> : t("The available diagram for this test method will not be shown in the report. Click the switch above to include it.")}

                {isFilePicked === true ? (<div style={{ marginTop: "20px" }}>
                    <p style={{ marginRight: '20px', marginBottom: "15px" }}>{t("Selected file")}: <b> {selectedFile[0].name}</b></p>
                    <Button
                        endIcon={<UploadIcon />}
                        variant="contained"
                        onClick={handleSubmission}>
                        {t("Upload file")}
                    </Button>
                    <Button
                        style={{ marginLeft: '10px' }}
                        variant="contained"
                        onClick={() => { setSelectedFile([]); setIsFilePicked(false); setDiagramName()}}>
                        {t("cancel")}
                    </Button>
                </div>) : null}


            </Paper>
            <input type="file"
                ref={fileInput}
                accept="image/*"
                onChange={fileChangeHandler}
                style={{ display: 'none' }} />


            <br /><br />
            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
        </div>
    )

}

export default DiagramComponent