/* eslint-disable react-hooks/exhaustive-deps */

import { DialogContentText, Divider, TextField, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleSelector } from "../SelectComponents";
import { getReasonsByCategoryId } from "../../services/CategoryService";
import { getClients } from "../../services/ClientsService";
import { getSampleTypes } from "../../services/SampleTypesService";
import { getDepartments} from "../../services/DepartmentsService";


const benefits = ["Brand Stewardship", "Innovation", "Productivity", "Sustainability"];

function AddJobPageOne(props) {

    const { shown, job, setJob, setSaveBtnDisabled, page, setPage } = props;

    const [reasonsForSubmission, setReasonsForSubmission] = useState([])
    const [reasonsOpts, setReasonsOpts] = useState([])
    const [selectedReason, setSelectedReason] = useState({ label: "select...", value: null })

    const [sampleTypes, setSampleTypes] = useState([])
    const [departments, setDepartments] = useState({id: -1})
    const [sampleTypesOpts, setSampleTypesOpts] = useState([])
    const [selectedSampleType, setSelectedSampleType] = useState({ label: "select...", value: null })
    const [selectedDepartment, setSelectedDepartment] = useState({ label: "select...", value: null })

    const [departmentsOpts, setDepartmentsOpts] = useState([])


    const [category, setCategory] = useState({id: -1});

    const [pilot, setPilot] = useState("");
    const [clientDetails, setClientDetails] = useState([])
   
    const { t } = useTranslation();


    useEffect(() => {
        if (shown) {
            getClients().then((res) => {
                console.log("GET client details call...")
                setClientDetails(res)

                getSampleTypes().then((res2) => {
                    console.log("GET sampleTypes call...")
                    setSaveBtnDisabled(false)
                    setSampleTypes(res2)
                });
                getDepartments().then((res2) => {
                    console.log("GET departments call...")
                    setSaveBtnDisabled(false)
                    setDepartments(res2)
                });
            });
        }
    }, [shown])


   

    useEffect(() => {
        if (job) {
           
            var cat = null;
            if (job.sampleType && job.sampleType.category && job.sampleType.category.id) {
                cat = job.sampleType.category;
            }

            if (cat && (cat.id !== category.id)) {
                setCategory(cat)
                getReasonsByCategoryId(cat.id).then((res) => {
                    console.log("GET reasons call...")
                    setReasonsForSubmission(res)
                   
                })
            }

            if (!selectedReason.value && job.reason) {
                setSelectedReason({ label: job.reason.name, value: job.reason })
            }
            if (!selectedDepartment.value && job.department) {
                setSelectedDepartment({ label: job.department.name, value: job.department})
            }

            if (!selectedSampleType.value && job.sampleType && Object.keys(job.sampleType).length > 0) {
                setSelectedSampleType({ label: job.sampleType.name, value: job.sampleType })
            }

            adjustSampleIds(job)

            console.log(job)
            if (!page) setPage(1)
        }

    }, [job])



    useEffect(() => {
        const opts = reasonsForSubmission.map((reason) => { return { value: reason, label: reason.name } })
        setReasonsOpts(opts);
        if (job && job.reason) {
            if (!opts.find((opt) => { return opt.value.id === job.reason.id })) {
                if (opts.length > 0) setSelectedReason(opts[0])
                else setSelectedReason({ label: "select...", value: null })
            }
        }
       
    }, [reasonsForSubmission])


   
    useEffect(() => {
        if (selectedReason && selectedReason.value) {
            job.reason = selectedReason.value;
            job.jobChanged = true;
            storeJob();
        }
    }, [selectedReason])

    useEffect(() => {
        if (selectedDepartment && selectedDepartment.value) {
            job.department = selectedDepartment.value;
            job.jobChanged = true;
            storeJob();
        }
    }, [selectedDepartment])

    useEffect(() => {
        if (sampleTypes.length > 0) {
            const opts = sampleTypes.map((st) => { return { value: st, label: st.name } })
            setSampleTypesOpts(opts)
        }
    }, [sampleTypes])

  
    useEffect(() => {
        if (departments.length > 0) {
            const opts = departments.map((d) => { return { value: d, label: d.name } })
            setDepartmentsOpts(opts)
        }
    }, [departments])


    useEffect(() => {
        if (selectedSampleType && selectedSampleType.value) {
            job.sampleType = selectedSampleType.value;
            job.sampleTypeName = selectedSampleType.label;
            job.jobChanged = true;
            storeJob();

        }
        
    }, [selectedSampleType])


    const storeJob = () => {
       setJob(JSON.parse(JSON.stringify(job)))
    }


    const isObjectEmpty = (obj) => {
        if (!obj) return true;
        return Object.keys(obj).length === 0
    }

   
    const setJobID = (e) => {
        job.jobID = e.target.value;
        job.jobChanged = true;
        adjustSampleIds();
        storeJob();
    }

    



    const setClient = (option) => {
        job.client = option.value;
        job.jobChanged = true;
        storeJob();
    }


 


    const setSampleCount = (e) => {
        let value = 0
        value = parseInt(e.target.value);
        if (value < 0) value = 0;
        job.sampleCount = value;
        adjustSampleIds();
        job.jobChanged = true;
        storeJob();

    }

    const adjustSampleIds = (j) => {
        if (!j) j = job;
        j.sampleIDs = [];
        let prefix = j.jobID; 
        for (var n = 0; n < j.sampleCount; n++) {
            j.sampleIDs.push(`${prefix}-${n + 1}`)
        }
    }


    

    const setBenefit = (option) => {
        job.benefit = option.value;
        job.jobChanged = true;
        storeJob();
    }

    const handlePilotChange = (event) => {
        setPilot(event.target.value);
    }

    useEffect(() => {
    if (pilot === "pilot") {
        job.pilot = true;
        job.jobChanged = true;
        storeJob();
    }
    if (pilot === "commercial") {
        job.pilot = false;
        job.jobChanged = true;
        storeJob();
    }
    }, [pilot])

    

    const setDueDate = (date) => {
        job.dueDate = date;
        job.jobChanged = true;
        storeJob();
    };

    const setDataSubmittedBy = (e) => {
        job.dataSubmittedBy = e.target.value;
        job.jobChanged = true;
        storeJob();
    };

    const setNotes = (e) => {
        job.notes = e.target.value;
        job.jobChanged = true;
        storeJob();
    }

    const setPepsicoDevEmail = (e) => {
        job.pepsicoDeveloperEmail = e.target.value;
        job.jobChanged = true;
        storeJob();
    }

    const getSampleIDs = () => {
        if (job && job.sampleIDs) {
            if (job.sampleIDs.length === 1) return `${job.sampleIDs[0]}`
            if (job.sampleIDs.length === 2) return `${job.sampleIDs[0]}, ${job.sampleIDs[1]}`
            else if (job.sampleIDs.length > 2) return `${job.sampleIDs[0]}, ..., ${job.sampleIDs[job.sampleIDs.length - 1]}`
            else return "";
        }

    };
  
   
    if (shown) return (
        <div>
            <h2>{t("Job Details")}</h2>
            <table  style={{width:"70%"}}>
                <tr>
                    <td style={{ padding: "20px", verticalAlign: "top"}}>
                        <DialogContentText>{t('Basic details')}...</DialogContentText>

                        <div style={{ marginBottom: "5px" }}>{t("GTPR (Job ID)")}:<span style={{ color: "red" }}>*</span> </div>

                        <TextField
                                inputProps={{ style: { padding: 10, width: "20vw" } }}
                                required={true}
                                id="jobID"
                                placeholder="type here..."
                                type="text"
                                value={job.jobID}
                                variant="outlined"
                                onChange={setJobID} />

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>{t('Client') }:<span style={{ color: "red" }}>*</span></div>

                        <SingleSelector
                                width="21vw"
                                options={clientDetails!==null ? clientDetails.map((client) => { return { value: client, label: client.name } }):[]}
                                value={isObjectEmpty(job.client)? null :{ value: job.client, label: job.client.name }}
                                placeholder="Select..."
                                handleSelect={setClient} />

                        <div style={{ marginTop: "30px" }}>{ t('Due date')}:<span style={{ color: "red" }}>*</span> </div>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={"Set " + t("due_date_label")}
                                    value={job.dueDate}
                                    onChange={setDueDate}      
                                    disablePast
                                    InputProps={{style: {width: "20vw"}}}
                                    KeyboardButtonProps={{"aria-label": "change date",}}
                                />
                        </MuiPickersUtilsProvider>
                
                    </td>
                    <td style={{ padding: "20px", verticalAlign: "top"}}>
                       
                        <DialogContentText>{t("sample_details_header") + "..."}</DialogContentText>

                        <div style={{ marginBottom: "5px" }}>{t("Sample Type")}:<span style={{ color: "red" }}>*</span> </div>

                        <SingleSelector
                            width="21vw"
                            options={sampleTypesOpts}
                            value={selectedSampleType}
                            placeholder="Select..."
                            handleSelect={(opt) => { setSelectedSampleType(opt) }} />

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>{t("sample_count_label")}:<span style={{ color: "red" }}>*</span> </div>

                            <TextField
                                inputProps={{style: {padding: 10,width: "20vw"}}}
                                required
                                variant="outlined"
                                id="sample-count"
                                defaultValue={0}
                                type="number"
                                value={job.sampleCount}
                                onChange={setSampleCount} />

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}></div>

                         <TextField
                            inputProps={{ style: { padding: 10, width: "20vw" } }}
                            required
                            variant="outlined"
                            id="sample-ids"
                            placeholder={t("sample_ids_label")}
                            type="text"
                            disabled
                            value={getSampleIDs()}
                            onChange={() => { }} />

                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>{t("reason_for_submission_label")}<span style={{ color: "red" }}>*</span>  <span style={{color:"grey"}}>(depends on selected sample type)</span></div>
                       {/* reasonsForSubmission.map((reason) => { return {value: reason, label: reason.name } })}*/}
                        { /* job.reason ? { value: job.reason, label: job.reason.name } : null*/}
                        { /*  setSubmissionReason*/}
                        {<SingleSelector
                            width="21vw"
                            options={reasonsOpts && reasonsOpts.length > 0 ? reasonsOpts: []} 
                            value={selectedReason}
                            placeholder="Select..."
                            handleSelect={(opt) => setSelectedReason(opt)} />}

                        
                     
                    </td>
                   
                    <td style={{ padding: "20px", verticalAlign: "top" }}>
                        <DialogContentText>{t('Optional details')}...</DialogContentText>
                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>{t("benefit_to_the_business_label")}:</div>

                        <SingleSelector
                            width="21vw"
                            value={job.benefit ? { value: job.benefit, label: job.benefit } : null}
                            options={benefits.map((benefit) => { return { value: benefit, label: benefit } })}
                            placeholder="Select..."
                            handleSelect={setBenefit} />

                        <div style={{ marginTop: "30px" }}>{t('pilot_or_commercial_label')}:</div>
                       
                        <RadioGroup 
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onClick={handlePilotChange}
                            row >
                            
                            <FormControlLabel checked={job.pilot == true ? true : false} value="pilot" control={<Radio />} label={t('pilot_label')} />
                            <FormControlLabel checked={job.pilot == false ? true : false} value="commercial" control={<Radio />} label={t('commercial_label')} />
                        </RadioGroup>
                        
                        <div style={{ marginTop: "30px" }}>{t("Department")}:<span style={{ color: "red" }}>*</span> </div>

                        <SingleSelector
                            width="21vw"
                            options={departmentsOpts}
                            value={selectedDepartment}
                            placeholder="Select..."
                            handleSelect={(opt) => { setSelectedDepartment(opt) }} />

                    </td>

                    <td style={{ padding: "20px", verticalAlign: "top" }}>
                        <DialogContentText>{t('Optional details')}...</DialogContentText>
                        <div style={{ marginTop: "20px", marginBottom: "5px" }}>{ t('PepsiCo Developer Email')}:<span style={{ color: "red" }}>*</span> </div>

                        <TextField
                            inputProps={{ style: { padding: 10, width: "20vw" } }}
                            required
                            variant="outlined"
                            id="pepsicoDevEmail"
                            placeholder={t("Enter PepsiCo Developer Email...")}
                            type="text"
                            value={job.pepsicoDeveloperEmail}
                            onChange={setPepsicoDevEmail} />

                        <div style={{ marginTop: "30px" }}>{ t('Data Submitted By')}:<span style={{ color: "red" }}>*</span> </div>

                        <TextField
                            inputProps={{ style: { padding: 10, width: "20vw" } }}
                            required
                            variant="outlined"
                            id="submittedBy"
                            placeholder={t("Enter name...")}
                            type="text"
                            value={job.dataSubmittedBy}
                            onChange={setDataSubmittedBy} />

                    </td>
                </tr>
            </table>
            <div style={{ textAlign: "right", marginRight: "250px" }}><span style={{ color: "red" }}>*</span> {t('required fields') }</div>

            
            <Divider />
                 

                <div style={{ width: "70%", marginTop:"15px", marginLeft:"20px" }}>
                    <TextField
                        InputProps={{style: {width: "50vw"}}}
                        id="id-notes"
                        label={t("notes_label")+"..."}
                        type="text"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={job.notes}
                        onChange={setNotes} />
                </div>

           
              
       
        </div>
    );
    else return <></>
}



export default AddJobPageOne;





