/* eslint-disable react-hooks/exhaustive-deps */


import { Box, makeStyles, Button, TextField, LinearProgress, Tooltip,Typography, Divider, Paper, Tabs, Tab, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { getOpenReports, getClosedReports,getLegacyReports  } from '../../services/RegisterJobService';
import {  jobReopen } from '../../services/ReportsService'
import { parseDate } from '../Reports/JobInfoTable'
import { useStyles } from "./styles"
import { styled } from '@mui/material/styles';
import ViewIcon from "@material-ui/icons/Visibility";
import IconButton from '@material-ui/core/IconButton';
import OpenIcon from '@mui/icons-material/OpenInBrowser';
import DashIcon from '@mui/icons-material/HorizontalRule';
import SearchIcon from "@material-ui/icons/Search";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import OpenReportIcon from '@mui/icons-material/AssignmentOutlined';
import { SingleSelector } from '../SelectComponents'

//import RestartAltIcon from ;
import ClearIcon from  '@mui/icons-material/Clear';


export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const ReportsTable = (props) => {
    const classes = useStyles();
    const dense = props.dense;
    const history = props.history;
    const [openRows, setOpenRows] = useState([]);
    const [closedRows, setClosedRows] = useState([]);
    const { t } = useTranslation();
    
    const [tabValue, setTabValue] = React.useState(0);
    const [alertContext, setAlertContext] = useContext(AlertContext);

    const placeHolderOpen = { label: "Search open reports...", value: null }
    const placeHolderClosed = { label: "Search closed reports...", value: null }

    const [selectedOpenRow, setSelectedOpenRow] = useState(placeHolderOpen)
    const [selectedClosedRow, setSelectedClosedRow] = useState(placeHolderClosed)

    const [openRowsOpts, setOpenRowsOpts] = useState([]);
    const [closedRowsOpts, setClosedRowsOpts] = useState([]);

    useEffect(() => {
        populateTable();
    }, [])


    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const table = params.get('table')

        if (table && table === "closed") {
           // populateTable()
            setTabValue(1);
        }
    }, [window.location.search])


    const progressFix = (res) => {
        res.forEach((r) => {
            if (!r.testsCompleted) return;
            if (!r.numberOfTests) return;
            if (r.testsCompleted > r.numberOfTests) r.numberOfTests = r.testsCompleted
        })
    }

    const sortByJobId = (a, b) => {
        if (a.label > b.label) return 1;
        else if (a.label < b.label) return -1;
        return 0;
    }

    const sortByAddDate = (a, b) => {
        if (a.addDate < b.addDate) return 1
        else if (a.addDate > b.addDate) return -1
        else return 0
    }

    const populateTable = () => {
      //  setTimeoutElapsed(false)
      //  setTimeout(() => { setTimeoutElapsed(true) }, 3000);
        setOpenRows([]);
        setClosedRows([]);

        if (tabValue === 0) {
            getOpenReports().then((res) => {
                if (res) {
                    progressFix(res);
                    res.sort(sortByAddDate)
                    setOpenRows(res);
                    var opts = res.map((r) => { return { label: r.jobID, value: r } })
                    opts.sort(sortByJobId)
                    setOpenRowsOpts(opts)
                }
            })
        }
        else if (tabValue === 1) {
            getClosedReports().then((res) => {
                if (res) {
                    progressFix(res);
                    res.sort(sortByAddDate)
                    setClosedRows(res);
                    var opts = res.map((r) => { return { label: r.jobID, value: r } })
                    opts.sort(sortByJobId);
                    setClosedRowsOpts(opts)
                		}
            })
        }
       else if (tabValue === 2) {
            getLegacyReports().then((res) => {
                if (res) {
                    progressFix(res);
                    res.sort(sortByAddDate)
                    setClosedRows(res);
                    var opts = res.map((r) => { return { label: r.jobID, value: r } })
                    opts.sort(sortByJobId);
                    setClosedRowsOpts(opts)
                		}
            })
        }

        
    }

    const getUpdatedBy = (updatedBy) => {
        return (updatedBy ? updatedBy : <span style={{ color: "lightgrey" }}><DashIcon /></span> )
    }

    const getSignedOffBy = (name) => {
        return (name ? name : <span style={{ color: "lightgrey" }}><DashIcon /></span> )
    }

    const handleTabChange = (event, newTabValue) => {

        setTabValue(newTabValue);
    };

    useEffect(() => {
        populateTable();
    }, [tabValue])

     
    const getActionButton = (row) => {
        if (tabValue === 0) return (
            <Box>
                <Tooltip followCursor={true} title={t('Open Report')}><IconButton onClick={() => { history.push(`/reports/?open=true&jobId=${row.id}`) }}> <OpenIcon /> </IconButton></Tooltip>
                <Tooltip followCursor={true} title={t('Go to Testing')}><IconButton onClick={() => { history.push(`/testing/?testsTable=true&jobId=${row.id}`) }}> <OpenReportIcon /> </IconButton></Tooltip>
            </Box>
        )
        else if (tabValue === 1 || tabValue === 2) return (
                <Tooltip followCursor={true} title={t("View Report")}><IconButton onClick={() => { history.push(`/reports/?open=true&jobId=${row.id}`) } }> <ViewIcon /> </IconButton></Tooltip>            
        )
        
    }
    //  <Tooltip followCursor={true} title={t("Re-open job")} >  </Tooltip>
    const getReopenButton = (row) => {
        return (
            <IconButton onClick={() => {
                jobReopen(row.id).then((res) => { populateTable()})
            }}> <LockOpenIcon /> </IconButton>)
    }

    const getTooltipText = (row) => {
        var line1 = row.testsCompleted + " " + t("out of") + " " + (row.numberOfTests === null ? "NaN" : row.numberOfTests) + " " + t("tests completed") + ".";
        var line2 = ` - ${row.testsPassed} ${t("tests passed")}.`
        var line3 = ` - ${row.testsCompleted - row.testsPassed} ${t("tests failed")} or ignored.`
        return (<div style={{ fontSize: "12px" }}>{line1}<br />{line2}<br />{line3}</div>)
    }

    // A custom progress indicator for progress column....
    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        backgroundColor: theme.palette.grey[300],
    }));

    // Gets the progress for a given row...
    const getProgress = (row) => {
        var progress = Math.floor((row.testsCompleted / (row.numberOfTests === null ? 0 : row.numberOfTests)) * 100)
        if (isNaN(progress)) progress = 0;
        if (progress > 100) progress = 100;
        return (
            <Tooltip followCursor={true} title={getTooltipText(row)}>
                <div style={{ minWidth: "100px", display: 'flex'}}>
                    <div style={{minWidth: "40%"}}>
                        {progress + "% " + t("complete")}
                        <CustomLinearProgress variant="determinate" value={progress} />
                    </div>
                    <div style={{ marginLeft: '5px', marginRight: '15px', color: 'red'}}>
                        {row.state===4?t("( pending sign-off )"):null}
                    </div>
                </div>
            </Tooltip>
        )

    }
    const cols = [
        { selector: row => row.jobID, sortable: true, name: t("job_id_label"), type: "text", key: "jobID" },
        { selector: row => getProgress(row), sortable: false, name: t("Progress"), type: "text", key: "progress" },
        { selector: row => row.client.name, sortable: true, name: t("client_label"), type: "text", key: "client" },
        { selector: row => parseDate(row.addDate, 'DD/MM/YYYY'), sortable: true, sortFunction: sortByAddDate, name: t("add_date_label"), type: "date", key: "addedAt" },
        { selector: row => row.numberOfTests, sortable: true, name: t("Tests"), type: "text", key: "numberOfTests" },
        { selector: row => getUpdatedBy(row.updatedBy), sortable: true, name: t("Updated By"), type: "text", key: "updatedBy" },
        { selector: row => getActionButton(row), sortable: false, name:t("Action"), key: "action" },
    ]

    const closedCols = [
        { selector: row => row.jobID, sortable: true, name: t("job_id_label"), type: "text", key: "jobID" },
        { selector: row => getProgress(row), sortable: false, name: t("Progress"), type: "text", key: "progress" },
        { selector: row => row.client.name, sortable: true, name: t("client_label"), type: "text", key: "client" },
        { selector: row => parseDate(row.addDate, 'DD/MM/YYYY'), sortable: true, sortFunction: sortByAddDate, name: t("add_date_label"), type: "date", key: "addedAt" },
        { selector: row => row.numberOfTests, sortable: true, name: t("Tests"), type: "text", key: "numberOfTests" },
        { selector: row => getSignedOffBy(row.signedOffBy), sortable: true, name: t("Signed off by"), type: "text", key: "signedOffBy" },
        { selector: row => getReopenButton(row), sortable: false, name: t("Re-open"), key: "reopen" },
//        { selector: row => getUpdatedBy(row.updatedBy), sortable: true, name: t("Updated By"), type: "text", key: "updatedBy" },
        { selector: row => getActionButton(row), sortable: false, name: t("Action"), key: "action" },
    ]

    const legacyCols = [
        { selector: row => row.jobID, sortable: true, name: t("job_id_label"), type: "text", key: "jobID" },
        { selector: row => parseDate(row.addDate, 'DD/MM/YYYY'), sortable: true, sortFunction: sortByAddDate, name: t("add_date_label"), type: "date", key: "addedAt" },
        { selector: row => row.numberOfTests, sortable: true, name: t("Tests"), type: "text", key: "numberOfTests" },
        { selector: row => getSignedOffBy(row.signedOffBy), sortable: true, name: t("Signed off by"), type: "text", key: "signedOffBy" },
        { selector: row => getActionButton(row), sortable: false, name: t("Action"), key: "action" },
    ]

    //|| timeoutElapsed === true

    return (
        <Paper className={classes.paper}>
           
            <Box mx={2} className={classes.tableHeader}>
                <Typography style={{ fontWeight: 400, fontSize: "20px" }}>
                {tabValue === 0 ? t("Open")+" " :"" }
                {tabValue === 1 ? t("Closed")+" ":"" }
                {tabValue === 2 ? t("Legacy")+" ":"" }

                {t("reports_title")}</Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} className={classes.tabBar} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label={t("Open" ) }/>
                        <Tab label={t("Closed")} />
                        <Tab label={t("Legacy")} />
                    </Tabs>
                </Box>
                <Typography style={{ fontWeight: 400, fontSize: "20px", marginLeft: "80px" }}>
                </Typography>

                {tabValue === 0 && <div style={{ display: "flex", marginLeft: "50px" }}>
                  
                    <SingleSelector
                        options={openRowsOpts}
                        value={selectedOpenRow}
                        onInputChange={(e) => {
                            var temp = openRows.filter((r) => { return r.jobID.toLowerCase().startsWith(e.toLowerCase())})
                            if (temp.length > 0)  setOpenRows(temp)
                           
                        }}
                        icon={<SearchIcon />}
                        handleSelect={(opt) => {
                           setSelectedOpenRow(opt);
                           setOpenRows([opt.value])
                        }} />

                    <Tooltip title="Reset filter / Show all">
                        <div style={{padding:"8px"}}>
                            <IconButton size="small" style={{ backgroundColor: "#F0F0F0", marginLeft: "5px" }}
                                onClick={() => {
                                    setSelectedOpenRow(placeHolderOpen);
                                    populateTable();
                                }}>
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    </Tooltip>
                   
                </div>}

                {tabValue === 1 && <div style={{ display: "flex", marginLeft: "50px" }}>
                    <SingleSelector
                        options={closedRowsOpts}
                        value={selectedClosedRow}
                        onInputChange={(e) => {
                            var temp = closedRows.filter((r) => { return r.jobID.toLowerCase().startsWith(e.toLowerCase()) })
                            if (temp.length > 0) setClosedRows(temp)
                        }}
                        icon={<SearchIcon />}
                        handleSelect={(opt) => {
                            setSelectedClosedRow(opt);
                            setClosedRows([opt.value])
                        }} />

                    <Tooltip title="Reset filter / Show all">
                        <div style={{ padding: "8px" }}>
                            <IconButton size="small" style={{ backgroundColor: "#F0F0F0", marginLeft: "5px" }}
                                onClick={() => {
                                    setSelectedClosedRow(placeHolderClosed);
                                    populateTable();
                                }}>
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    </Tooltip>

                </div>}

                {tabValue === 2 && <div style={{ display: "flex", marginLeft: "50px" }}>
                    <SingleSelector
                        options={closedRowsOpts}
                        value={selectedClosedRow}
                        onInputChange={(e) => {
                            var temp = closedRows.filter((r) => { return r.jobID.toLowerCase().startsWith(e.toLowerCase()) })
                            if (temp.length > 0) setClosedRows(temp)
                        }}
                        icon={<SearchIcon />}
                        handleSelect={(opt) => {
                            setSelectedClosedRow(opt);
                            setClosedRows([opt.value])
                        }} />

                    <Tooltip title="Reset filter / Show all">
                        <div style={{ padding: "8px" }}>
                            <IconButton size="small" style={{ backgroundColor: "#F0F0F0", marginLeft: "5px" }}
                                onClick={() => {
                                    setSelectedClosedRow(placeHolderClosed);
                                    populateTable();
                                }}>
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                    </Tooltip>

                </div>}
                
            </Box>

            <TabPanel value={tabValue} index={0}>
                {openRows.length > 0  ?
                    <DataTable id="reportsOpen"
                        customStyles={{
                            table: { style: { width: "85vw" } },
                            headCells: {
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: "15px"
                                },
                            }
                        }}
                        pagination
                        paginationPerPage={100}
                        paginationRowsPerPageOptions={[20, 60, 100, 140]}
                        striped
                        noDataComponent="No results..."
                        columns={cols}
                        data={openRows}
                        dense={+dense}
                        clearSelectedRows={() => { }}
                        paginationComponentOptions={{
                            rowsPerPageText: t("rows_per_page_label"),
                            rangeSeparatorText: t("range_seperator_label"),
                        }}
                    />
                    : <div style={{ textAlign: "center", marginBottom: "50px" }}><p>{t("Loading open reports")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
                {closedRows.length > 0  ?
                    <DataTable id="repportsClosed"
                        customStyles={{
                            headCells: {
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: "15px"
                                },
                            }
                        }}
                        pagination
                        paginationPerPage={ 100}
                        paginationRowsPerPageOptions={[20, 60, 100, 140]}
                        
                        striped
                        noDataComponent="No results..."
                        columns={closedCols}
                        data={closedRows}
                        dense={+dense}
                        clearSelectedRows={() => { }}
                        paginationComponentOptions={{
                            rowsPerPageText: t("rows_per_page_label"),
                            rangeSeparatorText: t("range_seperator_label"),
                        }}
                    />
                    : <div style={{ textAlign: "center", marginBottom: "50px" }}><p>{t("Loading closed reports")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
                {closedRows.length > 0  ?
                    <DataTable id="repportsLegacy"
                        customStyles={{
                            headCells: {
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: "15px"
                                },
                            }
                        }}
                        pagination
                        paginationPerPage={ 100}
                        paginationRowsPerPageOptions={[20, 60, 100, 140]}
                        
                        striped
                        noDataComponent="No results..."
                        columns={legacyCols}
                        data={closedRows}
                        dense={+dense}
                        clearSelectedRows={() => { }}
                        paginationComponentOptions={{
                            rowsPerPageText: t("rows_per_page_label"),
                            rangeSeparatorText: t("range_seperator_label"),
                        }}
                    />
                    : <div style={{ textAlign: "center", marginBottom: "50px" }}><p>{t("Loading legacy reports")}...</p> <CircularProgress style={{ marginBottom: "50px" }} /></div>}
            </TabPanel>
        </Paper>
    )

}

export default ReportsTable