import { Box, Paper, Tooltip, makeStyles, Input, Button, TextField, Typography, Divider, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { Clear, ArrowLeft, ArrowRight, Menu } from '@material-ui/icons';
// import ApplyJobProfile from './ApplyJobProfile'

import { Select } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import SweepSetSampleInfoDialog from "../Dialogs/SweepSetSampleInfoDialog"
import { saveSweepSetSamples } from '../../services/SweepSetService';
import OKDialog from "../Dialogs/OKDialog";


const useStyles = makeStyles(theme => ({

    pageHeading: {
        display: "flex",
        alignItems: "center",
    },
    title: {
        flex: "1 1",
        whiteSpace: "nowrap",
        marginBottom: "1rem"
    },
    paper: {

        marginBottom: theme.spacing(2),
    },
    paperHeader: {
        paddingTop: "15px",
        display: "flex",
        alignItems: "center",
        overflow: "visible",

    },
    tableCellDiv: {
        textAlign: "center",
        border: "1px solid black",
        borderRadius: "5px",
        paddingTop: "150px",
        paddingBottom: "150px"
    },
    componentContainer: {
        maxHeight: "450px",
        overflowY: "auto",

    }
}));



const SweepSetDetails = (props) => {
    
    const { t } = useTranslation();
    const classes = useStyles();
    const { index, set, handleRemoveSet, handleMoveSet, handleSetChange, handleSaveSweepSet, pepsico, allBrands, allSubBrands, allSizes, allCategories } = props;


    const [openDialog, setOpenDialog] = useState(false);


    const [newSet, setNewSet] = useState({...set});
    
    const LEFT = -1;
    const RIGHT = 1;


    const onDialogClose = (newSweepSetSampleInfoList) => {
        if (newSweepSetSampleInfoList && newSweepSetSampleInfoList.length > 0) {
            saveSweepSetSamples(newSweepSetSampleInfoList).then((res) => {
                setOpenDialog(false);
            });
        }
        else {
            setOpenDialog(false);

        }
    };

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })

    const validateFields = () => {
        // var j = sweepJob;

        // const isObjectEmpty = (obj) => {
        //     return Object.keys(obj).length === 0
        // }
        if (pepsico) {
            if (!newSet.brands || newSet.brands.length === 0) {
                setOKDialogState({
                    shown: true, title: t("Empty Brands field!"),
                    message: t("You need to add at least 1 Brand!"),
                    flavour: "error"
                })
                
                return false;
            }
    
            if (!newSet.subBrands || newSet.subBrands.length === 0) {
                setOKDialogState({
                    shown: true, title: t("Empty Sub Brands field!"),
                    message: t("You need to add at least 1 Sub Brand!"),
                    flavour: "error"
                })
                return false;
            }
    

            if (!newSet.sizes || newSet.sizes.length === 0) {
                setOKDialogState({
                    shown: true, title: t("Sample Sizes field!"),
                    message: t(`You need to add at least 1 Size!`),
                    flavour: "error"
                })
                
                return false;
            }
        }
        else {
            if (!newSet.brandsOther || newSet.brandsOther.length === 0) {
                setOKDialogState({
                    shown: true, title: t("Empty Brands field!"),
                    message: t("Please specify the Brand"),
                    flavour: "error"
                })
                
                return false;
            }

            if (!newSet.subBrandsOther || newSet.subBrandsOther.length === 0) {
                setOKDialogState({
                    shown: true, title: t("Empty Sub Brands field!"),
                    message: t("Please specify the Sub Brand"),
                    flavour: "error"
                })
                
                return false;
            }

            if (!newSet.sizesOther || newSet.sizesOther.length === 0) {
                setOKDialogState({
                    shown: true, title: t("Empty Sizes field!"),
                    message: t("Please specify the Size"),
                    flavour: "error"
                })
                
                return false;
            }
        }

        if (!newSet.packageType || newSet.packageType.length === 0) {
            setOKDialogState({
                shown: true, title: t("Empty Package Types field!"),
                message: t(`You need to add at least 1 Package Type!`),
                flavour: "error"
            })
            
            return false;
        }

        if (!newSet.sampleCount || newSet.sampleCount <= 0) {
            setOKDialogState({
                shown: true, title: t("Empty Sample count field!"),
                message: t(`Please add the number of samples for all sets`),
                flavour: "error"
            })
            
            return false;
        }

        return true;
    }


    const handleEditSampleInfo = async () => {
        if (validateFields()) {
            
            handleSaveSweepSet(index).then((res) => {
                setOpenDialog(true);
            })
        }
    };

    const handleSampleCountChange = (event) => {
        newSet.sampleCount = parseInt(event.target.value);
        handleSetChange(index, newSet);
    }

    const handleBrandsChange = (event) => {
        newSet.brands = event.target.value;
        handleSetChange(index, newSet);
    };

    const handleBrandsOtherChange = (event) => {
        newSet.brandsOther = event.target.value;
        handleSetChange(index, newSet);
    };

    const handleSubBrandsChange = (event) => {
        newSet.subBrands = event.target.value;
        handleSetChange(index, newSet);
    };

    const handleSubBrandsOtherChange = (event) => {
        newSet.subBrandsOther = event.target.value;
        handleSetChange(index, newSet);
    };

    const handleSizesChange = (event) => {
        newSet.sizes = event.target.value;
        handleSetChange(index, newSet);
    };

    const handleSizesOtherChange = (event) => {
        newSet.sizesOther = event.target.value;
        handleSetChange(index, newSet);
    };

    const handleCategoriesChange = (event) => {
        newSet.packageType = event.target.value;
        handleSetChange(index, newSet);
    };

    const handleSweepSetSamplesListChange = (newList) => {
        console.log(newList);
    };


    return (
        <Box style={{display:"flex", flexDirection:"row", border: "5px solid", borderColor: pepsico === true ? "rgb(50, 107, 240, 0.5)" : "rgb(199, 44, 108, 0.5)", borderRadius: "10px", padding: "1em", marginTop: "5px"}}>
            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
            
            {set.samples && 
                <SweepSetSampleInfoDialog openDialog={openDialog} onDialogClose={onDialogClose} sweepSetSampleInfoList={set.samples} handleSweepSetSamplesListChange={handleSweepSetSamplesListChange}></SweepSetSampleInfoDialog>
            }
            
            <div>
                {index >= 0 && 
                    <div>Set {index + 1}</div>
                
                }
                
                <div>

                    <Typography variant="body1">
                        Brands:<span style={{ color: "red" }}>*</span> 
                    </Typography>
                    <div>
                        {pepsico === true 
                            // ? <SingleSelector width={275} isMulti={false} placeholder={t("Brands")}
                            // // value={sweepJob.brands && { value: profile, label: profile.name }}
                            // // options={sweepJob.brands.map((brand) => ({ value: brand, label: brand.name }))}
                            // options={allBrands.map((brand) => ({ value: brand, label: brand.name }))}
                            // handleSelect={(e) => { setNewProfileSelected(true); setProfile(e.value); }} /> 
                            ? <Select
                                multiple
                                required
                                displayEmpty
                                // disabled={editDisabled}
                                value={newSet.brands}
                                onChange={handleBrandsChange}
                                input={<Input />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                    }

                                    return (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value.id}
                                                    label={value.name}
                                                    className={classes.chip}
                                                />
                                            ))}
                                        </div>
                                    );
                                }}
                                // MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}>
                                {allBrands.map((equipment) => (
                                    <MenuItem key={equipment.id} value={equipment}>
                                        {equipment.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            
                            : <TextField onChange={handleBrandsOtherChange} className={classes.dialogInput} margin="dense" id="brandsOther" type="input" value={newSet.brandsOther} variant="outlined" required placeholder={t("Brand...")} />
                            
                        }
                        
                    </div>

                </div>

                <div>

                    <Typography variant="body1">
                        Sub-Brands:<span style={{ color: "red" }}>*</span> 
                    </Typography>
                    <div>
                        {pepsico === true 
                            // ? <SingleSelector width={275} isMulti={false} placeholder={t("Brands")}
                            // // value={sweepJob.brands && { value: profile, label: profile.name }}
                            // // options={sweepJob.brands.map((brand) => ({ value: brand, label: brand.name }))}
                            // options={allBrands.map((brand) => ({ value: brand, label: brand.name }))}
                            // handleSelect={(e) => { setNewProfileSelected(true); setProfile(e.value); }} /> 
                            ? <Select
                                multiple
                                required
                                displayEmpty
                                // disabled={editDisabled}
                                value={newSet.subBrands}
                                onChange={handleSubBrandsChange}
                                input={<Input />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                    }

                                    return (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value.id}
                                                    label={value.name}
                                                    className={classes.chip}
                                                />
                                            ))}
                                        </div>
                                    );
                                }}
                                inputProps={{ "aria-label": "Without label" }}>
                                {allSubBrands.map((equipment) => (
                                    <MenuItem key={equipment.id} value={equipment}>
                                        {equipment.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            
                            : <TextField onChange={handleSubBrandsOtherChange} className={classes.dialogInput} margin="dense" id="subBrandsOther" type="input" value={newSet.subBrandsOther} variant="outlined" required placeholder={t("Brand...")} />
                            
                        }
                        
                    </div>

                </div>

                <div>

                    <Typography variant="body1">
                        Sizes:<span style={{ color: "red" }}>*</span> 
                    </Typography>
                    <div>
                        {pepsico === true 
                            // ? <SingleSelector width={275} isMulti={false} placeholder={t("Sizes")}
                            //     // value={sweepJob.brands && { value: profile, label: profile.name }}
                            //     // options={sweepJob.sizes.map((size) => ({ value: size, label: size.name }))}
                            //     options={allSizes.map((size) => ({ value: size, label: size.name }))}
                            //     handleSelect={(e) => { setNewProfileSelected(true); setProfile(e.value); }} />
                            ? <Select
                                multiple
                                required
                                displayEmpty
                                // disabled={editDisabled}
                                value={newSet.sizes}
                                onChange={handleSizesChange}
                                input={<Input />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em style={{ color: "grey" }}>{t("Select all that apply")}...</em>;
                                    }

                                    return (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value.id}
                                                    label={value.name}
                                                    className={classes.chip}
                                                />
                                            ))}
                                        </div>
                                    );
                                }}
                                // MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}>
                                {allSizes.map((size) => (
                                    <MenuItem key={size.id} value={size}>
                                        {size.name}
                                    </MenuItem>
                                ))}
                            </Select>

                            : <TextField onChange={handleSizesOtherChange} className={classes.dialogInput} margin="dense" id="sizesOther" type="input" value={newSet.sizesOther} variant="outlined" required placeholder={t("Size...")} />
                        }
                    </div>

                </div>

                <div>

                    <Typography variant="body1">
                        Package Types:<span style={{ color: "red" }}>*</span> 
                    </Typography>
                    <div>
                        <Select
                            displayEmpty
                            required
                            // disabled={editDisabled}
                            value={newSet.packageType}
                            onChange={handleCategoriesChange}
                            input={<Input />}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <em style={{ color: "grey" }}>{t("Select a Package Type")}...</em>;
                                }

                                return (
                                    <Typography>{selected.name}</Typography>
                                );
                            }}
                            // MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}>
                            {allCategories.map((equipment) => (
                                <MenuItem key={equipment.id} value={equipment}>
                                    {equipment.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                </div>

                <div>

                    <Typography variant="body1">
                        Number of Samples:<span style={{ color: "red" }}>*</span> 
                    </Typography>
                    <div>
                        <TextField required onChange={handleSampleCountChange} className={classes.dialogInput} margin="dense" id="sampleCount" type="number" value={newSet.sampleCount} variant="outlined" placeholder={t("Number of Samples...")} />
                    </div>

                </div>

            </div>

            <div style={{display:"flex", flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <Tooltip title={"Move Left"}>
                        <IconButton onClick={() => {handleMoveSet(index, LEFT)}}>
                            <ArrowLeft />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={"Move Right"}>
                        <IconButton onClick={() => {handleMoveSet(index, RIGHT)}}>
                            <ArrowRight />
                        </IconButton>
                    </Tooltip>
                </div>

                <div>
                    <Tooltip title={"Edit Sample Info"}>
                        <IconButton onClick={handleEditSampleInfo}>
                            <Menu />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={"Remove Set"}>
                        <IconButton onClick={() => {handleRemoveSet(index)}}>
                            <Clear />
                        </IconButton>
                    </Tooltip>

                    
                </div>
                
            </div>
            
        </Box>
    )

}




export default SweepSetDetails;

