
import { Dialog, DialogTitle, DialogActions, DialogContentText, TextField, Tooltip, Button, DialogContent } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import IconButton from '@material-ui/core/IconButton';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Checkbox from '@mui/material/Checkbox';
import $ from 'jquery';

const EditCellDialog = (props) => {
    const fileInput = React.useRef();
    const [comment, setComment] = useState(null);
    const [ignore, setIgnore] = useState(false);
    const [value, setValue] = useState(null);
    const [msg, setMsg] = useState(null)
    const [selectedFile, setSelectedFile] = useState([]);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [universal, setUniversal] = useState(false);
    const [tabClicked, setTabClicked] = useState(false);
    const [valueInput, setValueInput] = useState(null)
    const { t } = useTranslation();

   
    useEffect(() => {
        if (valueInput) valueInput.focus();
    }, [valueInput])


    useEffect(() => {
        setComment(props.comment);
        if (props.show) setUniversal(false)
        if (props.dataType === "Pass/Fail") {
            if (props.value === "fail") setValue(0);
            else setValue(1)
        }
        else setValue(props.value);
    }, [props.show])

    const fileChangeHandler = (event) => {
        var file = event.target.files[0];
        var fileExt = file.name.split('.').pop();
        setSelectedFile([file, fileExt]);
        setIsFilePicked(true);
    };

    

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    }

    const handleValueChange = (e) => {
        setMsg(null)
        setValue(e.target.value);
    }

   
    const getCheckBox = () => {
        return (
            <Tooltip title={<div style={{ fontSize: "12px" }}>* If checked, the image you upload will<br />represent all results for this test / column.</div>}>
                <tr>
                    <td><Checkbox
                        checked={universal}
                        onChange={(e) => { setUniversal(e.target.checked); }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />Make global * </td>
                </tr>
            </Tooltip>
        )
    }

    useEffect(() => {
        if (tabClicked === true) {
            document.getElementById("submit").click();
        }
    }, [tabClicked])
    
    return (
        <div>

            <Dialog
                open={props.show}
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        setTabClicked(true);
                    }
                }}
                onClose={(event, reason) => {

                    if (reason && reason == "backdropClick") return;
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <div><b>{'Edit result for:'}</b></div><br />
                    <div>{props.info}</div><br/>
                  
                    <form onSubmit={
                        (e) => {
							console.log("ignore="+ignore);                           
                            e.preventDefault();
                          
                            let _tabClicked = tabClicked;
                            setTabClicked(false);
                            let parsedVal = parseFloat(value)
                            if (!ignore)
                            {
								setIgnore(false)
								
	                            if (isNaN(parsedVal)) {
	                               
	                                setMsg(<p style={{ color: "red" }}>{t('Invalid value specified. It must be a number!')}</p>)
	                                return;
	                                
	                            }
                            }
                            let _comment = comment;
                            let _selectedFile = selectedFile
                           
                            setComment(null)
                            setValue(null)
                            setIsFilePicked(false);
                            setIgnore(false)
                          
                            setSelectedFile([])
                            props.callback(parsedVal, _comment, _selectedFile, universal, _tabClicked,ignore)

                        }}>
                        <div style={{ marginBottom: "10px" }}>{t('Edit value...')} {props.dataType === "Pass/Fail" ? t("( 1 = pass, 0 = Fail )"):"" }</div>
                        <TextField
                            inputProps={{ style: { padding: 10, width: 320 } }}
                            required={!ignore}
                            inputRef={(el) => { setValueInput(el)}}
                            placeholder={t('Type value...')}
                            type="text"
							autoComplete="off"
                            value={value}
                            variant="outlined"
                            onChange={handleValueChange}
                            
                        />
                        <div style={{ marginTop: "25px", marginBottom: "10px" }}>{t('Add / Edit comment...')}</div>
                        <TextField
                            inputProps={{ style: { padding: 10, width: 320 } }}
                            id="comment"
                            placeholder={t('Type a comment...')}
							autoComplete="off"
                            type="text"
                            value={comment}
                            variant="outlined"
                            onChange={handleCommentChange}
                           
                        />

                        <div style={{ marginTop: "25px", padding:"15px", border:"1px solid lightGrey", borderRadius:"5px"}}>
                        <table >
                            {!isFilePicked ? <>
                                <tr>
                                    <td>{t('Add an image...')}</td>
                                        <td><Tooltip title={<div style={{ fontSize: "12px" }}>{t("Select an image file to upload.")}</div>} >
                                        <IconButton variant="contained" onClick={() => fileInput.current.click()}><FolderOpenIcon /></IconButton>
                                        </Tooltip >
                                    </td>
                                </tr> 
                                {getCheckBox()}
                            </> : null
                            

                            }
                            {isFilePicked ? <><tr>
                                <td><div >{t('Selected file')}:<br/> <b> {selectedFile[0].name}</b></div></td>
                                <td>
                                    <IconButton variant="contained" onClick={() => {
                                        setSelectedFile([]);
                                        setComment(null)
                                        setValue(null)
                                        setIsFilePicked(false);
                                    }} >
                                    <CancelIcon />
                                    </IconButton>
                                </td>
                                <td> {t('Clear')}</td>
                            </tr>
                            {getCheckBox()}

                            </> : null}
                            </table>
                            </div>
                       
                       

                      

                        <div style={{ marginTop: "55px", marginBottom: "10px", textAlign:"right"}}>
                           
                            <IconButton  variant="contained" type="submit" id="submit">
                               {props.okText ? props.okText : <SaveIcon />}
                            </IconButton>
                            
                            <IconButton variant="contained" onClick={() => { setIsFilePicked(false); props.callback(-1, "cancel_clicked") }} >
                               {props.cancelText ? props.cancelText : <CancelIcon />}
                            </IconButton>
            
                            <IconButton variant="contained" onClick={() => { setIgnore(true); props.callback(-1, "ignore_clicked") ;}}  >
                               {props.cancelText ? props.cancelText : <AirlineStopsIcon />}
                            </IconButton>
                           
                        </div>

                        <input type="file"
                            ref={fileInput}
                            accept="image/*"
                            onChange={fileChangeHandler}
                            style={{ display: 'none' }} />


                        <div style={{ marginBottom: "25px" }}></div>
                        {msg}
                    </form>
                </DialogContent>
               
            </Dialog>

        </div>
    );
}

export default EditCellDialog;