/* eslint-disable react-hooks/exhaustive-deps */

import {
    Box,
    Button,
    Card,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { savejobPage1, savejobPage2, getSweepJobById } from "../../services/SweepJobService";
import AddSweepJobPageOne from "./AddSweepJobPageOne";
import ApplySweepJobProfile from "./ApplySweepJobProfile";
import OKDialog from "../Dialogs/OKDialog";
import OKCancelDialog from "../Dialogs/OKCancelDialog";
import CircularProgress from '@mui/material/CircularProgress';
import EventListener from '../../services/EndpointListener';


const useStyles = makeStyles({
    footer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    filler: {
        flexGrow: 1,
    },
    navBtn: {
        marginLeft: "15px",
    }
});

 


const AddEditJob = (props) => {

    const {
        openAdd,
        openEdit,
        setOpenAdd,
        setOpenEdit,
        selectedItem,
        //updateTable,
        rows,
        setRows
    } = props;



    const emptySweepJob = {
        version: null,
        createdBy: null,
        active: true,
        id: null,
        dueDate: null,
        name: null,
        sectors: [],
        markets: [],
        categories: [],
        brands: [],
        sizes: []
    }

    const [page, setPage] = React.useState(0);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)
    const classes = useStyles();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [sweepJob, setSweepJob] = useState(emptySweepJob)
    const [saveMsg, setSaveMsg] = useState("Saving job...")
   

    const getSavingMsg = (msg) => {
        const spinner = <div style={{ marginTop: "20px" }}>
            <span style={{ marginRight: "15px", fontSize: "large" }}>{msg}</span>
            <CircularProgress size="1.5rem" />
        </div>
        return spinner;
    }

    useEffect(() => {
        if (openEdit) {
            if (selectedItem && selectedItem.id) {
                getSweepJobById(selectedItem.id).then((res) => {
                    if (res) {
                        setSweepJob(res)
                    }
                });
            }
        }
    }, [openEdit])

 

    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })

    const [OKCancelDialogState, setOKCancelDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })


    const revisitPage1 = () => { setPage(1); }
    const revisitPage2 = () => { setPage(2); }


    const validateFields = () => {
        var j = sweepJob;

        // const isObjectEmpty = (obj) => {
        //     return Object.keys(obj).length === 0
        // }
        // console.log(j)

        if (!j.name || j.name === "") {
            setOKDialogState({
                shown: true, title: t("Empty Name field!"),
                message: t(`You need to specify a Name!`),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.dueDate || j.dueDate === "") {
            setOKDialogState({
                shown: true, title: t("Empty Due Date field!"),
                message: t(`You need to select a Due Date!`),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }


        if (!j.sectors || j.sectors.length === 0) {
            setOKDialogState({
                shown: true, title: t("Empty Sectors field!"),
                message: t(`You need to add at least 1 Sector!`),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.markets || j.markets.length === 0) {
            setOKDialogState({
                shown: true, title: t("Empty Markets field!"),
                message: t("You need to add at least 1 Market!"),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.brands || j.brands.length === 0) {
            setOKDialogState({
                shown: true, title: t("Empty Brands field!"),
                message: t("You need to add at least 1 Brand!"),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.subBrands || j.subBrands.length === 0) {
            setOKDialogState({
                shown: true, title: t("Empty Sub Brands field!"),
                message: t("You need to add at least 1 Sub Brand!"),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.categories || j.categories.length === 0) {
            setOKDialogState({
                shown: true, title: t("Empty Package Types field!"),
                message: t(`You need to add at least 1 Package Type!`),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }

        if (!j.sizes || j.sizes.length === 0) {
            setOKDialogState({
                shown: true, title: t("Sample Sizes field!"),
                message: t(`You need to add at least 1 Size!`),
                flavour: "error", callback: revisitPage1
            })
            return false;
        }
        
        if (page === 2) {
            return validateFieldsSweepSet(); 
        }

        return true;
    }

    const validateFieldsSweepSet = () => {
        // var j = sweepJob;

        // const isObjectEmpty = (obj) => {
        //     return Object.keys(obj).length === 0
        // }

        let isValid = true;

		
        for(let i = 0; i < sweepJob.sweepSets.length; i++) {
		console.log(	sweepJob.sweepSets[i]);
		console.log(i+"/"+sweepJob.sweepSets.length+ " "+sweepJob.sweepSets[i]);		
            if (sweepJob.sweepSets[i].pepsico) {

                if (!sweepJob.sweepSets[i].brands || sweepJob.sweepSets[i].brands.length === 0) {
                    setOKDialogState({
                        shown: true, title: t("Empty Brands field!"),
                        message: t("You need to add at least 1 Brand!"),
                        flavour: "error", callback: revisitPage2
                    })
                    
                    isValid = false;
                    break;
                }
    
                if (!sweepJob.sweepSets[i].subBrands || sweepJob.sweepSets[i].subBrands.length === 0) {
                    setOKDialogState({
                        shown: true, title: t("Empty Sub Brands field!"),
                        message: t("You need to add at least 1 Sub Brand!"),
                        flavour: "error", callback: revisitPage2
                    })
                    isValid = false;
                    break;
                }

                if (!sweepJob.sweepSets[i].sizes || sweepJob.sweepSets[i].sizes.length === 0) {
                    setOKDialogState({
                        shown: true, title: t("Sample Sizes field!"),
                        message: t(`You need to add at least 1 Size!`),
                        flavour: "error", callback: revisitPage2
                    })
                    
                    isValid = false;
                    break;
                }
            }
            else {
                if (!sweepJob.sweepSets[i].brandsOther || sweepJob.sweepSets[i].brandsOther.length === 0) {
                    setOKDialogState({
                        shown: true, title: t("Empty Brands field!"),
                        message: t("You need to specify Brands!"),
                        flavour: "error"
                    })
                    
                    isValid = false;
                    break;
                }
    
                if (!sweepJob.sweepSets[i].subBrandsOther || sweepJob.sweepSets[i].subBrandsOther.length === 0) {
                    setOKDialogState({
                        shown: true, title: t("Empty Sub Brands field!"),
                        message: t("You need to specify Sub Brands!"),
                        flavour: "error"
                    })
                    
                    isValid = false;
                    break;
                }
    
                if (!sweepJob.sweepSets[i].sizesOther || sweepJob.sweepSets[i].sizesOther.length === 0) {
                    setOKDialogState({
                        shown: true, title: t("Empty Sizes field!"),
                        message: t("You need to specify Sizes!"),
                        flavour: "error"
                    })
                    
                    isValid = false;
                    break;
                }
            }

            if (!sweepJob.sweepSets[i].sampleCount || sweepJob.sweepSets[i].sampleCount <= 0) {
                setOKDialogState({
                    shown: true, title: t("Empty Sample count field!"),
                    message: t(`You need to specify a Sample Count!`),
                    flavour: "error", callback: revisitPage2
                })
                
                isValid = false;
                break;
            }

    
            if (!sweepJob.sweepSets[i].packageType || sweepJob.sweepSets[i].packageType.length === 0) {
                setOKDialogState({
                    shown: true, title: t("Empty Package Types field!"),
                    message: t(`You need to add at least 1 Package Type!`),
                    flavour: "error", callback: revisitPage2
                })
                
                isValid = false;
                break;
            }
    
        };

        return isValid;
    }



    const getSampleIDs = (job) => {
        if (job && job.sampleIDs) {
            if (job.sampleIDs.length === 1) return `${job.sampleIDs[0]}`
            if (job.sampleIDs.length === 2) return `${job.sampleIDs[0]}, ${job.sampleIDs[1]}`
            else if (job.sampleIDs.length > 2) return `${job.sampleIDs[0]}, ..., ${job.sampleIDs[job.sampleIDs.length - 1]}`
            else return "";
        }

    };


    const cleanUpJSON = () => {
        var j = JSON.parse(JSON.stringify(sweepJob));
        // j.sampleIDs = getSampleIDs(j);
        delete j.jobChanged;
        // if (j.tests == null) delete j.tests;
        return j;
    }

    

    const updateTable = (j) => {
        if (j.id) {
            var temp = rows.filter((r) => { return r.id !== j.id })
            temp.push(j)
            temp.sort((x, y) => {
                var a = x.addDate;
                var b = y.addDate;
                return a < b ? 1 : a > b ? -1 : 0;
            })
            setRows(temp)
            return
        }
    }

    



    const saveJob = async (goToProfilePage) => {
       
        let j = cleanUpJSON();

        console.log(j);
        
        setSaving(true);
        if (page === 1) {
            var res = await savejobPage1(j);
            setSaving(false);
            if (!res) {
                alert("Sweep Job couldn't be saved! Back-end error!");
                return;
            }
            if (goToProfilePage) {
                setSweepJob(res);
                setPage(2);
            }
            else {
                updateTable(res);
                setOpenEdit(false);
                setOpenAdd(false);
            }
        }
        else {
            console.log(j);
            var res2 = await savejobPage2(j.sweepSets);
            setSaving(false);
            if (!res2) {
                alert("Job couldn't be saved! Back-end error!");
                return;

            }
            updateTable(res2);
            setOpenEdit(false);
            setOpenAdd(false);
        }
    }


    const handleSaveClick = async (goToProfilePage) => {
        if (validateFields()) {
            setSaveBtnDisabled(true)
            await saveJob(goToProfilePage);
        } 
    };


    const close = () => {
        setOpenEdit(false);
        setOpenAdd(false);
    }

    const handleClose = () => {
        
        var j = sweepJob
        if (j && j.jobChanged === true) {
            setOKCancelDialogState({
                shown: true,
                title: t("Are you sure?"),
                okText: "Yes",
                cancelText: "No",
                message: t(`Discard unsaved changes and cancel?`),
                flavour: "warning",
                callback: (res) => { if (res === "OK") { close() } }
            })
        }
        else {
            close();
        }
    }

   
    return (
        <div>
        <Box>
            <Card style={{padding:"15px"}}>
                {/* <DialogTitle id="form-dialog-title">{openEdit ? t('Edit Job') : t("create_job")}</DialogTitle> */}
                <DialogTitle id="form-dialog-title">{openEdit ? "Edit Sweep Job" : "Create Sweep Job"}</DialogTitle>
                <DialogContent>
                        {sweepJob && 
                            <>
                            <AddSweepJobPageOne
                                shown={page === 1}
                                sweepJob={sweepJob}
                                setSweepJob={setSweepJob}
                                setSaveBtnDisabled={setSaveBtnDisabled}
                                page={page}
                                setPage={setPage}
                            />

                            <ApplySweepJobProfile
                                shown={page === 2}
                                sweepJob={sweepJob}
                                setSweepJob={setSweepJob}
                                openEdit={openEdit}
                                setSaveBtnDisabled={setSaveBtnDisabled} />
                            </>
                      }
                </DialogContent>
                <DialogActions>
                    <div className={classes.footer}>
                        <div className={classes.filler} />
                            
                            {saving ? getSavingMsg(saveMsg) : <>
                                {page === 2 && <Button className={classes.navBtn} onClick={revisitPage1} variant="contained" > {t("back")}</Button>}
                                {page === 1 && <Button
                                    className={classes.navBtn}
                                    onClick={() => { handleSaveClick(false) }}
                                    disabled={saveBtnDisabled}
                                    variant="contained" > {"save core job details and exit"}</Button>}
                                <Button
                                    className={classes.navBtn}
                                    onClick={() => { handleSaveClick(true) }}
                                    type="submit" variant="contained"
                                    disabled={saveBtnDisabled}
                                    >
                                    {page === 2 ? t("Save and Finish") : openEdit ? t("next - Manage sweep sets") : t("next - Sweep Sets")}
                                </Button>
                                <Button className={classes.navBtn} onClick={handleClose} variant="contained" > {t("cancel")}</Button>
                            </>}
                 
                    </div>
                </DialogActions>
            </Card>
         </Box>
        


         <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />

            <OKCancelDialog
                show={OKCancelDialogState.shown}
                title={OKCancelDialogState.title}
                message={OKCancelDialogState.message}
                flavour={OKCancelDialogState.flavour}
                okText={OKCancelDialogState.okText}
                cancelText={OKCancelDialogState.cancelText }
                callback={
                    (res) => {
                        OKCancelDialogState.callback(res)
                        setOKCancelDialogState({ ...OKCancelDialogState, shown: false });
                    }
                }
            />
       
        </div>
    );
}


export default AddEditJob;





