import { Assessment, Create, DesktopWindows, ListAlt, Settings } from "@material-ui/icons";
import {  useTranslation } from "react-i18next";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import UserService from '../keycloak.js';


const MenuLinks = () => {

    const { t } = useTranslation();

    const rtn =  {

        dashboard: {
            text: "Packlims",
            path: "/",
            icon: <DashboardOutlinedIcon />
        },

        labSetUp: {
            text: t("system_set_up_title"),
            path: "/labSetup",
            icon: <DesktopWindows />
        },

        registerSamples: {
            text: t("register_samples_title"),
            path: "/registerSamples",
            icon: <Create />
        },

        sweepJobs: {
            // text: t("register_samples_title"),
            text: "DTV Request",
            path: "/sweepJobs",
            icon: <Create />
        },

        testing: {
            text: t("testing_title"),
            path: "/testing",
            icon: <ListAlt />

        },
        reports : {
            text: t("reports_title"),
            path: "/reports",
            icon: <Assessment />

        },

        settings : {
            text: t("settings_title"),
            path: "/settings",
            icon: <Settings />
        },

        tutorials : {
            text: t("Tutorials"),
            path: "/tutorials",
            icon: <VideoIcon />
        }
    }
    return rtn;
    
}



export const menuFilter = (items) => {

    if (UserService.hasRole(["ADMIN", "LAB_SUPERVISOR", 'LAB_MANAGER'])) 
	{
			return items;
	}
    else {
        if (UserService.hasRole(["EOLAS_USER"])) {
            delete items.testing;
            delete items.reports;
            delete items.settings;
            return items;
        }
		if (UserService.hasRole(["DTV"])) {
	
			console.log("DTV");
			delete items.dashboard;
			delete items.labSetUp;
		    delete items.testing;
		    delete items.reports;
		    delete items.settings;
			delete items.registerSamples;
		    return items;
		}
        if (!UserService.hasRole(["ANALYST"])) delete items.reports;
        delete items.labSetUp;
        return items;
    }
}



export default MenuLinks;