import HttpService from "./HttpService";
const axios = HttpService.getAxiosClient();
const methods = HttpService.HttpMethods;
const baseURL = process.env.REACT_APP_BACKEND_URL;
const rootRequestMapping = "testing";



export const getTestResultsByIdocId = async (iDocId) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/testresultfullbysession/${iDocId}`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        console.error(err);
        return { req_error: err }
    }
}


const getTestsByIdocIdOld  = async (iDocId) => {
    try {
        let res = await axios.get(`${baseURL}${rootRequestMapping}/idoctests/${iDocId}`);
        return res.data;
    }
    catch (err) {
        console.error(err);
        return { req_error: err }
    }
}

export const getTestsByIdocId = async (iDocId,language) => {
    try {
        let res = await axios.get(`${baseURL}${rootRequestMapping}/idoctestspacklims/${iDocId}/${language}`);
        return res.data;
    }
    catch (err) {
        console.error(err);
        return { req_error: err }
        
    }
}


export const postIdocComment = async (comment, iDocId) => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/comments/${iDocId}`,
            method: methods.POST,
            data: {text: comment}
        }
        const res = await axios(req);
        return res.data;
    }
    catch (err) {
        console.error(err);
        return { req_error: err }
    }
}

export const getHistoryOpenSessions = async () => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/open`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return { req_error: err }
    }

}


export const getHistoryClosedSessions = async () => {
    try {
        const req = {
            url: `${baseURL}${rootRequestMapping}/closed`,
            method: methods.GET
        }
        const res = await axios(req);
        return res.data;
    } catch (err) {
        return { req_error: err }
    }

}