

export const testDataTypes = [
    {
        name: "Numerical",
        tooltip: ["A simple numerical test result."]
    },
    {
        name: "Calculated:Pct",
        tooltip: ["For bottles that have been exposed to high temperatures over a period of time. " +
            "From the Gawis, we have empty PET Dimensions values (before) and then filled PET Dimensions (after).",
            "The result is the percentage change in the dimensions of the bottles after being exposed to high temperatures."]
    },
    {
        name: "Calculated:Sub",
        tooltip: ["Weight of empty container = Tare Weight",
            "Add water to a certain level = Gross Weight",
            "Result = Net Weight (Gross Weight - Tare Weight)"]
    },

    {
        name: "Calculated:AXL",
        tooltip: ["Three diameter measurements are taken with callipers or Gawis: AB, EF and CD.",
            "Of AB and EF, the result is the ratio of the greater of the two to the CD value.",
            "If AB > EF, result = AB / CD. ",
            "If EF > AB, result = EF / CD."
        ]
    },
    {
        name: "Calculated:Perp",
        tooltip: ["Height of the bottle is measured around the rim at 4 different locations: 0, 90, 180 and 270 degrees. " +
            "The result is the difference between the largest and smallest readings.",
        ]
    },
    {
        name: "Calculated:Vol",
        tooltip: ["Temperature of the water is obtained.",
            "Specific gravity of the water at that temperature is obtained by referencing a standard table.",
            "Result = Net Volume ( Net weight / Specific Gravity )"]
    },
    {
        name: "Calculated:HFDistortion",
        tooltip: ["Hot Fill Distortion (new)"]
    },
    {
        name: "Calculated:HFDistortionPct",
        tooltip: ["Hot Fill Distortion for % Change"]
    },
    {
        name: "Calculated:Average",
        tooltip: ["Average"]
    }
	,
	{
		name: "Calculated:AverageBy3Samples",
	    tooltip: ["Average By 3 Samples"]
	},
    {
        name: "Pass/Fail",
        tooltip: ["A simple binary or boolean result."]
    },
    {
        name: "Image",
        tooltip: ["The test result is an image."]
    }
];
